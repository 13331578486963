@media (max-width: 1700px) {
    .pricing__card {
        padding: 30px 20px;
    }
    .pricing__title {
        font-size: 26px;
    }
    .pricing__subtitle {
        font-size: 14px;
    }
    .pricing__item {
        font-size: 14px;
    }
    .pricing__price {
        font-size: 30px;
    }
    .pricing__card .leading-\[30px\] {
        margin-bottom: 20px;
    }
}

@media (max-width: 1500px) {
    .saved-content-items-wrpr .saved-content-item.cols-6 {
        width: calc(20% - 20px);
    }
}

@media (max-width: 1199.98px) {
    .header {
        height: 64px;
        display: flex;
        justify-content: flex-end;
    }
    .header nav {
        position: fixed;
        left: -100%;
        top: 64px;
        z-index: 2;
        background-color: #111111;
        height: calc(100dvh - 64px);
        transition: 0.3s all ease-in-out;
        width: 100%;
    }
    .header nav.active {
        left: 0;
    }
    .mob_nav_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
    }
    .mob_nav_btn span {
        width: 26px;
        height: 3px;
        background: #A7A7A7;
        margin: 3px 0;
        transition: 0.3s all ease-in-out;
        border-radius: 3px;
    }
    .header nav.active+.mob_nav_btn span:nth-child(1){
        transform: rotate(48deg) translate(7px, 7px);
    }
    .header nav.active+.mob_nav_btn span:nth-child(2){
        transform: translateX(100%);
    }
    .header nav.active+.mob_nav_btn span:nth-child(3){
        transform: rotate(-48deg) translate(5px, -6px);
    }
    .header nav ul {
        flex-direction: column;
        width: 100%;
    }
    .header nav ul li+li {
        margin-left: 0px;
        margin-top: 15px;
    }
    .documents-card {
        width: calc(25% - 20px);
    }
}

@media (max-width: 991.98px) {
    .documents-card {
        width: calc(33.33% - 20px);
    }
}

@media (max-width: 767.98px) {
    .documents-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 575.98px) {
    .documents-cards-wrpr {
        padding: 0;
    }
    .documents-card {
        width: 100%;
    }
}