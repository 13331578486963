/* -- dop classes */
.ico{display:flex;align-items:center;}
.r-300{border-radius:300px;}
.r-12{border-radius:12px;}
.r-8{border-radius:8px !important;}
.border-left--none{border-left:none !important;}
.ico-20{min-width:20px;max-width:20px;height:20px;}
.ico-28{min-width:28px;max-width:28px;height:28px;}
.ico-18{min-width:18px;max-width:18px;height:18px;}
.def-list{
    display: flex;
    flex-direction: column;
}
.list--5 > .def-list--item:not(:last-child){
    margin-bottom: 5px;
}
.ico-rounded{
    overflow: hidden;
    border-radius: 300px;
}
.p-16px{padding: 16px;}
.p-32px{padding: 32px;}
.pt-8px{padding-top:8px;}
.pt-0{padding-top:0;}
.p-10px{padding:10px;}
.mt-6px{margin-top:6px;}
.mr-8px{margin-right:8px;}
.mt-16px{margin-top:16px;}
.mt-12px{margin-top:12px;}
.mt-30px{margin-top:30px;}
.px-14px{padding-left:14px;padding-right:14px;}
.py-12px{padding-top:12px;padding-bottom:12px;}
.font-600{font-weight:600;}
.font-500{font-weight:500;}
.font-300{font-weight:300;}
.text-12{font-size: 12px;line-height: 17px;}
.text-13{font-size: 13px;line-height: 17px;}
.text-14{font-size: 14px;line-height: 17px;}
.text-16{font-size: 16px;line-height: 22px;}
.text-32{font-size: 32px;line-height: 40px;}
.text-10{font-size: 10px;line-height: 22px;}
.text-white--09{color:rgba(256,256,256,0.9);}
.text-white{color:rgba(256,256,256,1);}
.text-white--03{color:rgba(256,256,256,0.3);}
.text-white--06{color:rgba(256,256,256,0.6);}
.italic{font-style:italic;}
.mt-5px{margin-top:5px;}
.px-5px{
    padding-left:5px;
    padding-right:5px;
}
.text-sec{color: #A7A7A7;}
.text-primary{color:#FF8114;}
.swiper-1{width:100%;
position: relative;}
.btn--prim-balck{
    border: 1px solid #333;
    background: #1B1B1B;
}
.btn-40{
    min-width: 40px;
    height: 40px;
    width: 40px;
}
.custom-row{
    display: flex;
    flex-wrap: wrap;
}
.custom-row--5 > div{
    flex: 0 0 20%;
    max-width: 20%;
}
.custom-row-15{
    margin: -7px;
    margin: -7px;
}
.custom-row-15 > div{
    padding: 7px;
    padding: 7px;
}

/* .dropdown {
    max-width: max-content;
    display: flex;
    flex-direction: column;
}
.dropdown.dropdown-open .btn{
    border-radius: 4px;
    background-color: #333;
}
.dropdown__body {
    display: none;
    position: fixed;    
    background-color: white;
    box-shadow: 0px 11px 20px rgba(0, 0, 0, .2);
    overflow: auto;
    margin-top: 8px;
    z-index: 9999;
}
.dropdown__body.is-open {
    display: block;
    white-space: nowrap;
} */
/* -- dop classes */

h2 {
    font-size: var(--h2Size);
    font-weight: bold;
}
h4 {
    font-size: var(--h4Size);
    font-weight: bold;
}
.p-20 {
    padding: 20px;
}
.pb-0 {
    padding-bottom: 0;
}
.pt-64 {
    padding-top: 64px;
}
.text-center {
    text-align: center;
}
.d-flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.items-center {
    align-items: center;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-48 {
    margin-bottom: 48px;
}
.radius-16 {
    border-radius: 16px;
}
.w-100 {
    width: 100%;
}
/* .header {
    padding: 6px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #111111;
    z-index: 10;
}
.header nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
}
.header nav ul {
    display: flex;
    align-items: center;
}
.header nav ul li+li {
    margin-left: 30px;
}
.header nav ul li a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #A7A7A7;
}
.header nav ul li a:hover {
    color: #FF8114;
}
.header nav ul li a .icon-wrpr {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header nav ul li a .icon-wrpr.icon-pos-left {
    margin-right: 15px;
}
.header nav ul li a .icon-wrpr.icon-pos-right {
    margin-left: 15px;
} */
.bg-gradient-section-box {
    border-radius: 16px;
    background: linear-gradient(180deg, #1B1B1B 0%, rgba(27, 27, 27, 0.00) 100%);
}
.main-title {
    color: #FFF;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
}
.product-desctription-box {
    padding: 48px 20px 0;
}
.search-input-form {
    position: relative;
    max-width: 600px;
    width: 100%;
    z-index: 2;
}
.search-input-form input {
    border-radius: 360px;
    background: rgba(255, 255, 255, 0.06);
    border: 1px solid #262626;
    padding: 11px 48px;
    display: block;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
}
.search-input-form input::placeholder {
    color: rgba(255, 255, 255, 0.25);
}
.search-input-form input:hover {
    border-color: #fff;
}
.search-input-form input:focus {
    border-color: #FF8114;
}
.search-input-form button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
}
.search-input-form.autofill-dropdown-open input {
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #1B1B1B;
    border-color: #262626;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
}
.categories-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 790px;
}
.categories-items li .category-item {
    display: inline-flex;
    align-items: center;
    border-radius: 360px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: rgba(0, 0, 0, 0.10);
    padding: 5px 8px;
    padding-left: 14px;
    margin: 4px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
}
.categories-items li .category-item span {
    border-radius: 360px;
    background: #A2A2A2;
    color: #171717;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    padding: 0px 4px;
    margin-left: 10px;
    transition: 0.15s all ease-in-out;
}
.categories-items li .category-item:hover,
.categories-items li .category-item.active {
    background: #FF8114;
    color: #1B1B1B;
}
.categories-items li .category-item:hover span,
.categories-items li .category-item.active span {
    background: #171717;
    color: #FF8114;
}

.infinite-scroll-component__outerdiv {
    width: 100%;
}
.documents-cards-wrpr {
    display: flex;
    /* max-width: 1335px; */
    width: 100%;
    padding: 0 20px 20px;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
}
.documents-card {
    border-radius: 16px;
    background: #1B1B1B;
    /* box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20); */
    padding: 16px;
    width: calc(20% - 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    color: #A2A2A2;
    transition: 0.3s all ease-in-out;
}
.documents-card:active {
    box-shadow: 8px 8px 26px 0 rgba(255, 130, 20, 0.7);
    color: #fff;
}
.documents-card .icon-wrpr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
}
.documents-card h3 {
    margin-bottom: 18px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: normal;
}
.documents-card p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.12px;
}
.documents-card:hover {
    /* background: #FF8114;
    color: #331A04;
    transform: translateY(-5px);
    box-shadow: 8px 8px 16px 0 rgba(255, 130, 20, 0.3); */
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #1B1B1B;
}
.documents-card:hover h3 {
    color: #1B1B1B;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 8px;
    border: 4px solid #FF8114;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #FF8114 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mob_nav_btn {
    display: none;
}
.header .sidebar-btn {
    display: none;
}
.header nav {
    margin-left: auto;
}
.sidebar-layout {
    display: flex;
}
.sidebar-layout aside.sidebar-nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 280px;
}
.wrapper.sidebar-fixed .header {
    width: calc(100% - 280px);
    right: 0;
    left: auto;
    transition: .2s;
}
.wrapper.sidebar-fixed .header .sidebar-btn {
    display: inline-flex;
}
.header__link {
    font-size: 14px;
}
.header__link svg {
    width: 20px;
    height: 20px;
}

.wrapper.sidebar-fixed {
    height: 100dvh;
}
.wrapper.sidebar-fixed .content {
    /* padding-top: 63px; */
    /* padding-left: 280px; */
    display: flex;
    align-items: flex-start;
    /* height: calc(100dvh - 63px); */
    height: 100dvh;
    transition: .2s;
    width: 100vw;
}
.sidebar-layout.sidebar-close aside.sidebar-nav {
    left: 0;
}
.sidebar-layout.sidebar-close .wrapper.sidebar-fixed .content {
    padding-left: 0;
}
.sidebar-layout.sidebar-close .wrapper.sidebar-fixed .header {
    width: calc(100% - 52px);
}
.toolbar-sidebar .blog-toolkit-wrapper {
    border-right: 0;
}
.toolbar-sidebar {
    /* min-width: 400px; */
    /* width: 400px; */
    z-index: 2;
    width: 24.3vw;
    min-height: 100vh;
    background: #151515;
    border-right: 1px solid #262626;
    display: flex;
    flex-direction: column;
}
.toolbar-sidebar-body {
    height: calc(100dvh - 140px);
    padding: 20px;
    border-radius: 16px 16px 0px 0px;
    background: #161616;
    overflow-y: auto;
}
.toolbar-sidebar:has(.toolbar-sidebar-footer) .toolbar-sidebar-body {
    /*height: calc(100dvh - 179px);*/
    height: calc(100dvh - 138px);
}

.toolbar-sidebar-footer {
    /*border-top: 1px solid #262626;*/
    padding: 16px;
    border-top: unset;
    background: #161616;
}
.toolbar-sidebar-footer .btn {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 20px;
    height: 40px;
    min-height: 40px;
}

.select-selection {
    border-radius: 8px !important;
    border: 1px solid #262626 !important;
    background: #1B1B1B !important;
    min-height: 40px !important;
    cursor: pointer !important;
    box-shadow: none !important;
}
.select-selection .aaa {
    color: #FFF !important;
    font-size: 14px !important;
    font-weight: 300 !important;
}
.select-selection .bbb {
    padding-left: 14px !important;
}
.select-menu-portal div {
    background: #232323;
}
.select-menu-portal .ccc.select2-dropdown {
    border-radius: 8px;
    border: 1px solid #393939;
    background: #232323;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    overflow: hidden;
    min-width: fit-content;
}
.select-menu-portal .select2-results__option {
    background: transparent;
    color: #A7A7A7;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 16px;
    cursor: pointer;
}
.select-menu-portal .select2-results__option.select2-results__option--selected {
    background: #232323;
    color: #FF8114;
}
.multi-select .select-selection .bbb {
    /* flex-direction: column; */
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 8px 0;
}
.multi-select .select-selection .bbb>.select-multi-value {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #232323;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value {
    color: rgba(255, 255, 255, 0.50);
    display: inline-flex;
    align-items: center;
    padding: 5px 3px;
    padding-left: 12px;
    line-height: 21px;
    font-size: 12px;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value:hover {
    background-color: transparent;
    color: #fff;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value {
    padding-left: 6px;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value svg {
    margin: 0;
    width: 16px;
    height: 16px;
}
.multi-select .select-selection .bbb>.select-multi-value .select-multi-value svg {
    margin-right: 6px;
}
.multi-select .indicators-container {
    display: none;
}
.multi-select .select-input {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    padding-bottom: 3px;
    height: 32px;
    flex: initial;
    line-height: 34px;
    padding-right: 16px;
}
.multi-select .select-input input {
    display: block !important;
    width: 100% !important;
    background: transparent !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #fff !important;
}
.multi-select .select-input input::placeholder {
    color: rgba(255, 255, 255, 0.25);
}
.multi-select .select-placeholder {
    display: none;
}

/* TODO trash */
.badges-group-wrpr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}
.badge-plus-item {
    padding: 5px 12px;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #161616;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    margin: 3px;
}
.badge-plus-item.active {
    background: rgba(255, 255, 255, 0.05);
}
.badge-plus-item .badge-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
}
.badge-plus-item:hover {
    background: #FF8114;
    color: #fff;
}

/* TODO trash  top */

.textarea-with-suggestion textarea {
    resize: none;
    width: 100%;
    display: block;
    padding-top: 4px;
    padding-left: 34px;
    background: transparent;
    border: 0;
    min-height: 142px;
    padding-bottom: 16px;
    
}
.textarea-with-suggestion {
    height: initial;
}
input::placeholder,
textarea::placeholder {
    color: rgba(255, 255, 255, 0.25);
}
.textarea-with-suggestion .input-icon {
    position: absolute;
    top: 16px;
    left: 16px;
}
.textarea-suggestion-block .badge-plus-item {
    background: #4B4B4B;
    font-size: 12px;
}
.textarea-suggestion-block p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
}
.input-custom__input {
    height: 40px;
    padding: 8px 14px;
}
.input-mt-10 {
    margin-top: 10px;
}
.input-custom__label {
    font-size: 12px;
}
.toolbar-sidebar-body::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.sidebars-wrpr {
    display: flex;
    align-items: flex-start;
    min-height: 100dvh;
    position: relative;
    transition: 0.3s all ease-in-out;
}
.content-box-wrpr {
    transition: 0.3s all ease-in-out;
    width: 100vw;
}
.sidebars-wrpr.toolkit-sidebar-close {
    width: 24.3vw;
}
.sidebars-wrpr.toolkit-sidebar-close+.content-box-wrpr {
    width: 75.7vw;
}
.sidebars-wrpr .blog-toolkit-wrapper {
    z-index: 1;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    visibility: visible;
    position: absolute;
    right: 0;
    top: 0;
    width: 24.7vw;
}
.sidebars-wrpr.toolkit-sidebar-close .blog-toolkit-wrapper {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
}
.select-menu-portal {
    z-index: 999 !important;
}
.default-select.searchable .select-input {
    display: flex !important;
    flex: 1;
}
.default-select.searchable .select-input input {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 300;
}
.default-select.searchable .select-selection.has-value:not(.select-selection-focused) .select-input,
.default-select.searchable .select-selection:not(.select-selection-focused) .aaa.has-value+.select-input {
    opacity: 0;
}
.default-select.searchable .select-selection .bbb {
    display: flex !important;
}
.default-select-options a:hover,
.default-select-options a.active {
    background: rgba(255, 255, 255, 0.10);
    color: #FF8114;
}
.default-select-options a.active {
    font-weight: 700;
}
.select-input textarea {
    width: 100%;
    display: block;
    resize: none;
    border: 0;
    background: transparent;
    line-height: normal;
    font-size: 14px;
    font-weight: 300;
}
.select-input.with-textarea {
    height: initial;
    margin-top: 16px;
}
/* 
@keyframes skeleton-animation {
    0% {
        right: 100%;
    }
    50% {
        right: -128px;
    }
    100% {
        right: 100%;
    }
}

.skeleton {
    overflow: hidden;
    position: relative;
}
.skeleton:before {
    display: block;
    position: absolute;
    width: 128px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF, rgba(255, 255, 255, 0));
    opacity: 0.7;
    content: "";
    animation: skeleton-animation 1.25s infinite;
    z-index: 2;
} */
.default-select.multi-select.one-line .select-input {
    width: initial;
    padding-left: 16px;
    flex: 1;
    min-width: 150px;
    margin: 0;
    padding-top: 7px;
}
.default-select.multi-select.one-line .select-input textarea {
    height: 26px;
}
.default-select.only-icon .select-selection .bbb {
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 !important;
}
.product-description-toolbar .select-selection {
    min-height: 18px !important;
    flex-wrap: initial !important;
    background: transparent !important;
    border: 0 !important;
}
.product-description-toolbar .select-selection-arrow {
    padding: 0 !important;
    padding-left: 8px !important;
}
.product-description-toolbar .select-selection-arrow svg {
    width: 20px;
    height: 18px;
}
.product-description-toolbar .select-selection-arrow svg path {
    fill: #A7A7A7;
    fill-opacity: 1;
}
.product-description-toolbar button {
    font-weight: 500;
    color: rgb(167, 167, 167);
}
.product-description-toolbar button:hover,
.product-description-toolbar button.active {
    color: #EA8207;
}
.content-box-header h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.title-edit-input.input-custom__input {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 0 5px;
    height: inherit;
}
.bb-1-grey {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.assets-page {
    width: 100%;
    border: 1px solid #262626;
    background: #161616;
    border-radius: 16px;
}
/* .assets-page-body {
    margin-top: -20px;
    z-index: 2;
    position: relative;
    background: #161616;
    border-radius: 16px;
} */
.page-grey-header {
    border-radius: 16px 16px 0 0;
    background: #A7A7A7;
    padding: 18px 20px 38px;
    color: #393939;
}
.page-grey-header h3 {
    color: #393939;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
.page-grey-header p {
    color: #393939;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}
.tabs-controls {
    display: flex;
    align-items: center;
}
.tabs-controls li+li {
    margin-left: 8px;
}
.tabs-controls.tabs-ml-fix li+li {
    margin-left: 0px;
}
.tabs-controls.tabs-ml-fix li {
    margin-right: 8px;
}
.tabs-controls.tabs-ml-fix li:last-child {
    margin-right: 0;
}
.tabs-controls li a {
    display: block;
    position: relative;
    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 17px 12px;
}
.tabs-controls li a:hover,
.tabs-controls li a.active {
    color: #fff;
}
.tabs-controls li a.active:after {
    content: "";
    position: absolute;
    display: block;
    height: 2px;
    border-radius: 4px;
    background: #FF8114;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}
.orange-rounded-drop-btn {
    border-radius: 360px;
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    border: 0;
    border-bottom: 1px solid;
    border-image-source: linear-gradient(360deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0) 100%);
}
.transparent-input-wrpr input {
    background: transparent;
    border-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    height: initial;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.transparent-input-wrpr.icon-left .input-custom__input {
    padding-left: 30px;
}
.transparent-input-wrpr.icon-left .icon-input {
    left: 0;
}
.saved-content-items-wrpr {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}
.saved-content-items-wrpr .saved-content-item {
    padding: 12px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #232323;
    box-shadow: 0px 4px 8px 0px #111, 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
    min-height: 204px;
    max-height: 204px;
}
.saved-content-items-wrpr .saved-content-item.cols-2 {
    width: calc(50% - 20px);
}
.saved-content-items-wrpr .saved-content-item .item-text {
    max-height: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow: hidden;
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
}
.saved-content-items-wrpr .cols-5 {
    width: calc(20% - 16px);
    margin: 8px;
}
.saved-content-items-wrpr .saved-content-item .multiple-images-wrpr {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    margin: -5px;
}
.saved-content-items-wrpr .saved-content-item .multiple-images-wrpr .img-wrpr {
    margin: 5px;
    width: calc(50% - 10px);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 85px;
    position: relative;
}
.saved-content-items-wrpr .saved-content-item .multiple-images-wrpr .img-wrpr img,
.saved-content-items-wrpr .saved-content-item .single-image {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}
.plus-images-count {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(17, 17, 17, 0.80) 0%, rgba(17, 17, 17, 0.80) 100%), lightgray 50% / cover no-repeat;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;

}
.saved-content-items-wrpr .saved-content-item:has(.single-image) {
    padding: 0;
    overflow: hidden;
    position: relative;
    max-height: 204px;
    display: flex;
    justify-content: center;
}
.saved-content-items-wrpr .saved-content-item-name {
    color: rgba(255, 255, 255, 0.9);
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 10px;
    margin-top: 16px;
    margin-bottom: 10px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 16px;
}
.saved-content-items-wrpr .saved-content-item-category {
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 300;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 13px;
}
.saved-content-item-card:hover {
    opacity: 0.7;
}
.saved-content-items-wrpr .saved-content-item.cols-6 {
    width: calc(16.666% - 20px);
}
.saved-content-items-wrpr .saved-content-item.row-view {
    width: calc(100% - 20px);
}
.saved-content-items-wrpr .saved-content-item h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #A7A7A7;
}
.saved-content-items-wrpr .saved-content-item p {
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
}
.saved-content-items-wrpr .saved-content-item.add-new-word-btn {
    border: 1px solid #EA8207;
    background: #161616;
    color: #FF8114;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    transition: 0.15s background, color ease-in-out;
}
.saved-content-items-wrpr .saved-content-item.add-new-word-btn:hover {
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #1B1B1B;
}
.default-select.sort-select-box .select-selection .bbb {
    padding-left: 4px !important;
}
.default-select.sort-select-box .select-selection .aaa {
    font-weight: bold !important;
    color: rgba(255, 255, 255, 0.50) !important;
}
.default-select.sort-select-box .select-selection {
    padding-left: 10px !important;
}
.assets-page .default-select.sort-select-box .select-selection,
.assets-page .btn-outline-black {
    background-color: #1B1B1B !important;
    border-color: #333 !important;
    min-height: 36px !important;
}
.assets-page .default-select.sort-select-box .select-selection-arrow {
    padding: 2px 8px !important;
}
.saved-content-items-wrpr.row-view .cols-5 {
    width: calc(100% - 16px);
}
.saved-content-items-wrpr.row-view .saved-content-item-card {
    display: flex;
    align-items: center;
}
.saved-content-items-wrpr.row-view .card-date {
    margin-left: auto;
    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
.saved-content-items-wrpr.row-view .saved-content-item {
    min-width: 90px;
    min-height: 90px;
    max-height: 90px;
    max-width: 90px;
    border-radius: 8px;
}
.saved-content-items-wrpr.row-view .saved-content-item {
    padding: 8px;
}
.saved-content-items-wrpr.row-view .saved-content-item:has(.img-wrpr) {
    padding: 4px;
}
.saved-content-items-wrpr.row-view .saved-content-item:has(.single-image){
    padding: 0;
}
.saved-content-items-wrpr.row-view .saved-content-item .multiple-images-wrpr {
    width: calc(100% + 1px);
    margin: -1px;
}
.saved-content-items-wrpr.row-view .saved-content-item .multiple-images-wrpr .img-wrpr {
    margin: 1px;
    width: calc(50% - 2px);
    border-radius: 6px;
    height: 39px;
}
.saved-content-items-wrpr.row-view .saved-content-item .item-text {
    max-height: 74px;
    font-size: 10px;
    line-height: 15px;
}
.saved-content-items-wrpr.row-view .saved-content-item-bottom-content {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 20px;
}
.saved-content-items-wrpr.row-view .saved-content-item-name {
    margin-bottom: 0;
    max-width: 45vw;
}
.saved-content-items-wrpr.row-view .saved-content-item-name,
.saved-content-items-wrpr.row-view .saved-content-item-category {
    padding: 0;
}
.saved-content-items-wrpr.row-view .saved-content-item.add-new-word-btn {
    font-size: 9px;
}
.saved-content-items-wrpr.row-view .saved-content-item.add-new-word-btn svg {
    max-width: 30px;
    max-height: 30px;
}
.dropdown__body.dropdown__body-lighten {
    /*border: 1px solid #FF8114;*/
    /*background: #232323;*/
    /*box-shadow: 4px 4px 16px 0px rgba(255, 129, 20, 0.25);*/

    font-size: 14px;
}
.dropdown-assets .btn {
    border: 1px solid transparent;
}
.dropdown-assets.dropdown-open  .btn {
    border: 1px solid #FF8114;
    background: transparent;
}
.dropdown-assets.dropdown-open svg path{
    stroke: #FFFFFF;
}

.dropdown-assets svg {
    transition: all 0.3s ease;
}

.dropdown-assets.dropdown-open svg:last-child {
    transform: rotate(180deg);
}

.back-arrow-link:hover {
    opacity: 0.7;
}
.product-description__item.active .circle-20x20 {
    position: relative;
    display: block;
    border-color: #fff;
}
.product-description__item.active .circle-20x20:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.auth-form-box-fullscreen .auth-form {
    width: 100%;
}
.text-generator-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-align: left;
}
.auth-form .input-custom__input.error {
    border-color: red;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 50px 50px #383838;
}
.upload-image {
    cursor: pointer;
}

.sidebar-layout.sidebar-to-right .sidebar-nav {
    right: 0;
    left: auto;
}
.sidebar-layout.sidebar-to-right.sidebar-close aside.sidebar-nav {
    right: -280px;
    left: auto;
}
.sidebar-layout.sidebar-to-right .wrapper.sidebar-fixed .header {
    left: 0;
    right: auto;
}

.code-input-wrpr.input-custom__wrap-input {
    flex-wrap: initial;
}

.product-description-header h2 {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

/* new auth */
.auth-fullscreen-wrpr {
    /* background: url(../img/react/auth-bg.jpg); */
    /* background-size: cover; */
    /* background-position: center; */
    background: #111;
}

.auth-fullscreen-wrpr * {
    font-family: 'Inter', sans-serif;
}

.auth-form-box-fullscreen {
    background: transparent;
    border: 0;
}
.auth-fullscreen-wrpr .auth-content,
.auth-form-box-fullscreen {
    min-height: calc(100dvh - 40px);
}
.auth-fullscreen-wrpr.split-screen .auth-form-box-fullscreen .auth-form {
    margin: auto;
    background: transparent;
    border: 0;
}
.auth-fullscreen-wrpr.split-screen .auth-content {
    /* border-radius: 24px;
    border: 1px solid #262626;
    background: url(../img/react/auth-content-bg.jpg);
    background-size: cover;
    background-position: center; */
    background: transparent;
    padding: 60px;
}
.auth-layout-video-bg {
    position: fixed;
    right: -38%;
    top: -140%;
    z-index: 1;
    width: 300%;
    height: 300%;
}
.auth-content-inner h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 32px;
}
.auth-content-inner p {
    line-height: 24px;
}
.auth-content-inner {
    margin: auto 0 0;
    max-width: 100%;
}
.auth-content-inner .logo {
    margin-bottom: 24px;
    max-width: 140px;
    margin-left: -11px;
}
.avatars-list li {
    border: 4px solid #1B1B1B;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
}
.avatars-list li+li {
    margin-left: -16px;
}
.auth-form .submit-btn {
    border-radius: 360px;
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    color: #331A04;
    padding: 9px 10px;
    min-height: 44px;
}
.or-separate-line {
    color: rgba(255, 255, 255, 0.33);
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    position: relative;
}
.or-separate-line:before,
.or-separate-line:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.08;
    background: #FFF;
    height: 1px;
    width: calc(38% - 20px);
}
.or-separate-line:before {
    left: 0;
}
.or-separate-line:after {
    right: 0;
}
.social-links-items {
    flex-direction: column;
}
.social-links-items li {
    width: 100%;
}
.social-links-items li+li {
    margin: 0;
    margin-top: 8px;
}
.social-links-items li button {
    border-radius: 360px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(15px);
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 500;
    border: 0;
    border-radius: 360px;
    position: relative;
}
.social-links-items li button svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}
.auth-form .input-custom__input {
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    /* backdrop-filter: blur(15px); */
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}
.auth-form .icon-input+.input-custom__input {
    padding-left: 46px;
}
.auth-form .input-custom__input::placeholder {
    color: rgba(255, 255, 255, 0.50);
}
.auth-form .input-custom__input:focus {
    border: 1px solid #EA8207;
}
.icon-left .icon-input {
    left: 14px;
}
.info-gray-box {
    background: rgba(255, 255, 255, 0.03);
}
.info-gray-box svg {
    margin-right: 12px;
}
.auth-left-top-logo {
    position: absolute;
    left: 20px;
    top: 20px;
    max-width: 104px;
}
.auth-form h3 {
    font-weight: 600;
    line-height: 36px;
}
.auth-dont-have p {
    font-weight: 400;
    line-height: 20px;
}
.auth-form .input-custom__label {
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}
.auth-form .code-input-wrpr input {
    font-size: 32px;
    font-weight: 400;
}
.auth-form .back-to-link {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.6;
}
.auth-form .back-to-link svg {
    margin-right: 12px;
}
.auth-form .back-to-link:hover {
    opacity: 1;
}
.auth-form .custom-checkbox-fake {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
}
.auth-form input:-webkit-autofill,
.auth-form input:-webkit-autofill:hover, 
.auth-form input:-webkit-autofill:focus, 
.auth-form input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 50px 50px rgba(22, 22, 22, 0.50);
}
.auth-form .custom-checkbox input:checked ~ .custom-checkbox-text {
    color: rgba(255, 255, 255, 0.5);
}
.custom-checkbox-fake:not(.normal-color) {
    border: 1.5px solid rgba(255, 255, 255, 0.08);
}
.custom-checkbox-text {
    margin-left: 12px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}
.sidebar-logo {
    max-width: 103px;
    margin-right: auto;
}
.content .content-box-body {
    background: transparent;
    border: 0;
    padding: 0 16px 16px 0;
    /* padding-right: 16px; */
    height: calc(100dvh - 140px);
}
.content .content-box-header {
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 16px 0;
}
.btn-outline-black-new {
    border: 1px solid #333;
    background: #1B1B1B;
    color: rgba(255, 255, 255, 0.6);
}
.content .product-description__group {
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    min-height: 226px;
    align-items: stretch;
}
.content .product-description__body {
    height: initial;
}
.product-description-header {
    padding: 11px 16px;
}
.content .product-description__body {
    border-radius: 0;
    border: 0;
    background: transparent;
    border-left: 1px solid #1F1F1F;
}
.product-description__list {
    padding: 16px;
    max-width: 270px;
}
.content .product-description__item {
    padding: 4px 0;
    border: 0;
}
.content .product-description__item+.product-description__item {
    margin-top: 14px;
}
.content .product-description__item.active {
    background: transparent;
    border: 0;
    position: relative;
}
.content .product-description__item-text {
    color: rgba(167, 167, 167, 0.3);
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    transition: 0.3s all ease-in-out;
}
.content .product-description__item-text:hover {
    color: rgba(167, 167, 167, 0.6);
}
.content .product-description__item.active .product-description__item-number,
.content .product-description__item.active .product-description__item-text {
    color: #A7A7A7;
}
.content .generated-content-footer {
    border-radius: 0;
    padding: 5px 16px;
    min-height: 66px;
    width: calc(100% + 20px);
    margin-left: -20px;
    border-top: 1px solid #1F1F1F;
}
.generated-content-footer__labels {
    padding-top: 0;
}
.generated-content-footer__labels li {
    margin-bottom: 0;
}
/* .generated-content-footer__labels {
    margin-bottom: 0;
} */
.generated-content-footer .btn {
    font-size: 14px;
    padding: 9px 12px;
}
.generated-content-footer .btn:not(.btn--primary-gradient) {
    color: rgba(255, 255, 255, 0.6);
}
.sm-vertical-line {
    width: 1px;
    height: 18px;
    background: #1F1F1F;
}
.content .product-description__textarea-footer {
    padding: 10px 16px;
    border: 0;
    min-height: 40px;
}
.color-fff-03 {
    color: rgba(255, 255, 255, 0.3);
}
.custom-checkbox-fake {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
}
.custom-checkbox input:checked + .custom-checkbox-fake {
    background-image: none;
    border-color: #FF8114;
    position: relative;
}
.custom-checkbox input:checked + .custom-checkbox-fake:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FF8114;
}
.custom-checkbox input:checked ~ .custom-checkbox-text {
    color: #FF8114;
}
.custom-checkbox-text.ml-\[6px\] {
    margin-left: 6px;
}
.product-description__textarea {
    line-height: 24px;
    max-height: 146px;
}
.product-description__item-circle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    background: #202020;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
}
.content .product-description__item.active .product-description__item-circle {
    background: #FF8114;
    color: #331A04;
}
.content .product-description__item.active:after {
    content: "";
    display: block;
    position: absolute;
    right: -22px;
    top: 0;
    height: 100%;
    width: 2px;
    background: #FF8114;
}
.content-box-body .multi-select .select-selection .bbb>.select-multi-value {
    border-radius: 24px;
}
.content-box-body .multi-select .select-selection .bbb>.select-multi-value .select-multi-value .circle-icon {
    display: none;
}
.content-box-body .multi-select .select-selection .bbb {
    padding: 7px 8px !important;
}
.content-box-body .multi-select .select-selection {
    min-height: 40px !important;
    border-radius: 360px !important;
    border: 1px solid #333 !important;
}
.content-box-body .default-select.multi-select.one-line .select-input {
    padding: 5px 0px 0 16px;
}
.content-box-body .multi-select .select-selection .bbb>.select-multi-value>.select-multi-value {
    padding: 2px 3px;
    padding-left: 10px;
}
.content-box-body .multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value {
    padding-right: 6px;
    padding-left: 3px;
}
.content-box-body .default-select.multi-select.one-line .select-input textarea {
    font-weight: 400;
}
.product-description-block.in-use .product-description__group {
    border-color: #FF8114;
}
.product-description__group-title {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.9;
}
.ck.ck-editor__main>.ck-editor__editable,
.ck.ck-toolbar {
    background: #151515;
    border-color: transparent;
}
.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
    color: #fff;
}
.ck.ck-powered-by {
    display: none;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: transparent;
}
.ck.ck-icon {
    color: #A7A7A7 !important;
}
.ck.ck-toolbar .ck.ck-toolbar__separator {
    background: #1F1F1F !important;
    height: 18px !important;
    margin-top: 10px !important;
}
.ck.ck-button.ck-on, a.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-splitbutton.ck-splitbutton_open>.ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
.ck.ck-splitbutton:hover>.ck-button:not(.ck-on):not(.ck-disabled):not(:hover) {
    background: #202020 !important;
}
.ck.ck-splitbutton.ck-splitbutton_open>.ck-splitbutton__arrow:not(.ck-disabled):after,
.ck.ck-splitbutton:hover>.ck-splitbutton__arrow:not(.ck-disabled):after {
    background: #202020 !important;
}
.ck.ck-dropdown__panel,
.ck.ck-balloon-panel {
    background: #202020 !important;
    border-color: #333 !important;
}
.ck.ck-form__row.ck-image-insert-form__action-row .ck-button .ck-button__label {
    color: #A7A7A7 !important;
}
.ck.ck-input {
    border: 1px solid #383838 !important;
    background: #202020 !important;
}
.ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
    background: #202020 !important;
}
.ck.ck-editor__main {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    min-height: 122px;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border-color: transparent !important;
}
.ck.ck-list {
    background: transparent !important;
}
.sidebar-nav__info {
    margin-bottom: 16px;
}
.assets-page .default-select.small-select .select-selection-arrow {
    padding: 0 !important;
}
.assets-page .default-select.small-select .select-selection-arrow svg {
    width: 20px;
}
.default-select.small-select .select-selection .bbb {
    padding: 0 !important;
}
.default-select.small-select .select-selection .aaa {
    font-weight: 400 !important;
}
.default-select.small-select .select-selection {
    padding-right: 6px;
}
.select-menu-portal .select2-results__option {
    white-space: nowrap;
}
.sidebar-nav.close .show-in-open {
    display: none;
}
.sidebar-nav.open .show-in-close {
    display: none;
}
.sidebar-layout aside.sidebar-nav.close {
    width: 52px;
}
.sidebar-nav.close .sidebar-nav__info {
    flex-direction: column;
    margin-bottom: 10px;
}
.sidebar-nav.close .sidebar-nav__info .user-actions-drop {
    margin-left: 0;
    margin-top: 20px;
    order: 1;
}
.sidebar-nav.close .sidebar-logo {
    order: 0;
}
.sidebar-nav.close .sidebar-nav__info .btn {
    order: 2;
    margin-top: 20px;
}
.sidebar-nav.close .user-actions-drop__body .btn {
    margin-top: 0;
}
.sidebar-nav.close .sidebar-nav__item {
    padding: 12px 9px;
}
.sidebar-nav.close .sidebar-nav__head:after {
    content: "";
    display: block;
    width: 18px;
    height: 1px;
    background: #1F1F1F;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}
.sidebar-nav.close .sidebar-divider {
    width: 18px;
    background: #1F1F1F;
}
.sidebar-nav.close .dropdown__body.is-open {
    right: auto !important;
    left: 16px;
    z-index: 2;
}
.sidebar-nav.close.dropdown__body-is-open {
    overflow: initial;
}
.sidebar-nav.close .sidebar-nav__title + .btn--primary-shadow {
    padding: 6px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
}
.sidebar-nav.close .sidebar-nav__title + .btn--primary-shadow .ml-\[10px\] {
    margin-left: 0;
}
.sidebar-nav.close .sidebar-nav__title + .btn--primary-shadow svg {
    max-width: 18px;
    max-height: 18px;
}
.sidebar-nav.close .sidebar-nav__list-icon {
    padding: 9px;
}
.lh-n {
    line-height: normal;
}
.sidebar-nav.close .sidebar-nav__list-link {
    display: flex;
    justify-content: center;
}
.modal {
    background: rgba(17, 17, 17, 0.6);
}
.modal__content {
    border-radius: 16px;
    border: 1px solid #333;
    background: #1B1B1B;
}
.modal-close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.5;
}
.modal-close-btn:hover {
    opacity: 1;
}
.modal__content {
    display: block;
    width: 100%;
}
.modal__body {
    padding: 30px 36px;
    display: block;
}
.share-platforms-items {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin: -8px;
    margin-bottom: 16px;
}
.share-platforms-item {
    width: calc(25% - 16px);
    margin: 8px;
    border-radius: 8px;
    background: #202020 !important;
    padding: 22px 14px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.50) !important;
    text-align: center;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
}
.share-platforms-item:hover {
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114 !important;
    color: #1B1B1B !important;
}
.input-custom__label.text-\[12px\] {
    font-size: 12px;
}
.toolbar-body-nav li {
    width: 100%;
}
.from-computer-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
}
.from-computer-box p {
    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 16px;
}
.blog-toolkit-content {
    flex: 1;
}
.from-computer-box-loaded {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.from-computer-box-loaded .img-wrpr {
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    max-height: 318px;
    min-height: 200px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.from-computer-box-loaded .img-wrpr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.from-computer-box-loaded .box-footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding-top: 20px;
}
.from-computer-box-loaded .box-footer .btn {
    padding: 9px 15px;
    min-height: 40px;
}
.my-assets-sydebar-images {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.my-assets-sydebar-image-item {
    overflow: hidden;
    border-radius: 8px;
    margin: 8px 5px;
    flex-grow: 1;
}
.my-assets-sydebar-image-item img {
    object-fit: cover;
    width: 100%;
    max-height: 130px;
    transform: scale(1.02);
    transition: 0.3s all ease-in-out;
}
.my-assets-sydebar-image-item img:hover {
    transform: scale(1.2);
}
.from-computer-box-loaded {
    max-height: calc(100dvh - 161px);
    overflow: hidden;
    overflow-y: auto;
}
.from-computer-box-loaded::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.grey-box-p {
    border-radius: 8px;
    background: #222;
    padding: 14px;
    margin: 16px 0;
}
.grey-box-p p {
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
}
.grey-box-p+p {
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    opacity: 0.4;
}
.toolbar-sidebar-header .btn-outline-secondary-2 {
    color: #767676;
}
.toolbar-sidebar-header .btn-outline-secondary-2.active-btn {
    border: 1px solid #FF8114;
    background: #1B1B1B;
    color: #FF8114;
}
.blog-toolkit-head form.toolbar-toolkit {
    border-top: 1px solid #262626;
    border-bottom: 0;
    min-height: 44px;
}
.text-editor-simple-btn {
    min-width: 30px;
    min-height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.text-editor-simple-btn:hover {
    background: #202020;
}

.steps-page {
    padding: 14px 20px;
    background: #111111;
    min-height: 100vh;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
}
.steps-page .steps-page-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    pointer-events: none;
}
.steps-page .steps-page-bg path {
    stroke-dasharray: 30;
    animation: dash 5s linear infinite;
}
  
@keyframes dash {
    to {
      stroke-dashoffset: 300;
    }
}

.steps-page-header {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    z-index: 2;
}
.steps-page-steps-numbers {
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.steps-page-steps-numbers li+li {
    margin-left: 76px;
}
.steps-page-steps-numbers li {
    position: relative;
}
.steps-page-steps-numbers li:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 1px;
    background: #333;
    right: calc(100% + 8px);
}
.steps-page-steps-numbers li:first-child::before {
    display: none;
}
.steps-page-steps-numbers li.active:before {
    background: #FF8114;
}
.steps-page-steps-numbers li a {
    border: 1px solid #333;
    background: #1B1B1B;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A7A7A7;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
.steps-page-steps-numbers li a.active {
    border-color: #FF8114;
    color: #FF8114;
    background: #111111;
}
.light-dark-template-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 360px;
    background: #1B1B1B;
    padding: 3px;
    position: relative;
}
.light-dark-template-switch a {
    padding: 6px 8px;
    padding-right: 12px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    z-index: 2;
}
.light-dark-template-switch:before {
    content: "";
    display: block;
    border-radius: 360px;
    background: #2B2B2B;
    height: 30px;
    width: 76px;
    left: 3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s all ease-in-out;
}
.light-dark-template-switch.dark:before {
    left: 79px;
}
.light-dark-template-switch.light a:nth-child(1) {
    color: rgba(255, 255, 255, 0.60);
}
.light-dark-template-switch.dark a:nth-child(2) {
    color: rgba(255, 255, 255, 0.60);
}
.steps-page-header .btn-outline-black {
    padding: 7px 13px;
    font-size: 14px;
}
.step-max-w-500 {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}
.steps-page-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    z-index: 2;
}
.steps-page-body .title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 32px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.1204px;
}
.steps-page-body .title b {
    font-weight: 700;
}
.steps-page-body .subtitle {
    color: rgba(167, 167, 167, 0.5);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.steps-page-body .input-custom__input {
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    padding: 8px 14px;
    height: 40px;
}
.steps-page-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.steps-page-footer-box {
    padding: 8px;
    border-radius: 360px;
    background: #151515;
    display: flex;
    align-items: center;
}
.steps-page-footer-box .btn--primary-gradient {
    font-size: 14px;
    font-weight: 600;
    padding: 7px 14px;
}
.steps-page-footer-box .dropdown {
    position: relative;
}
.steps-page-footer-box .dropdown h3 {
    padding-left: 14px;
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
.steps-page-footer-box .dropdown .dropdown__body {
    max-height: 310px;
    bottom: 45px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.steps-page-body .toolbar-body-header-container::after {
    display: none;
}
.steps-page-body .keywords-select-item {
    cursor: pointer;
    opacity: 1;
    transition: 0.15s all ease-in-out;
}
.steps-page-body .keywords-select-item:hover {
    opacity: 0.7 !important;
}
.steps-page-body .keywords-select-item.active {
    background: #FF8114;
    color: #331A04;
}
.steps-page-body textarea.input-custom__input {
    height: 220px;
    resize: none;
    padding: 16px 14px;
}
.suggestions-categories-btns-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -3px;
}
.suggestions-categories-btns-list .btn {
    padding: 14px 10px;
    border-radius: 8px;
    background: #202020;
    color: rgba(255, 255, 255, 0.50);
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    flex: 1;
    margin: 0 3px;
}
.suggestions-categories-btns-list .btn.active {
    background: #FF8114;
    color: #331A04;
}
.keywords-select-item.big-full-width {
    width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding: 9px 14px;
    justify-content: space-between;
}
.keywords-select-item.big-full-width svg {
    width: 20px;
    height: 20px;
}
.keywords-select-item.big-full-width+.keywords-select-item.big-full-width  {
    margin-top: 8px;
}
.keywords-select-item-custom-input {
    position: relative;
    width: 100%;
    margin-top: 8px;
}
.keywords-select-item-custom-input input {
    width: 100%;
    display: block;
    border-radius: 24px;
    border: 1px solid #383838;
    background: #111;
    height: 40px;
    padding: 11px 14px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.keywords-select-item-custom-input input::placeholder {
    color: rgba(255, 255, 255, 0.50);
}
.keywords-select-item-custom-input a {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 50%;
}
.keywords-select-item-custom-input a:hover {
    border: 1px solid #262626;
    background: #1B1B1B;
}
.steps-page-body .input-custom-wrapper.no-after {
    padding: 16px 8px 0;
}
.steps-page-body .input-custom-wrapper.no-after.pl-\[0\] {
    padding-left: 0;
}
.steps-page-body .input-custom-wrapper.no-after.pr-\[0\] {
    padding-right: 0;
}
.steps-page-body .select-selection {
    min-height: 40px !important;
}
.steps-page-body .select-selection-arrow {
    padding: 0px 8px;
}
.steps-page-body .select-selection-arrow svg {
    width: 24px;
    height: 24px;
}
.steps-page-body .select-selection-arrow svg path,
.steps-page-body .number-input .arrows-group-right svg path {
    fill-opacity: 0.5;
}
.steps-page-body .number-input .arrows-group-right svg {
    width: 8px;
    height: 8px;
}
.loading-circle-image {
    width: 70px;
    height: 70px;
    animation: lds-ring-2 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes lds-ring-2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
.steps-page-body .suggestion-list.mb-\[32px\] .suggestion-options-item {
    padding: 5px 8px;
    height: 22px;
}
.steps-page-body .input-custom__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}
.steps-page-body .step-5-keywords .input-custom__label span{
    display: none;
}
.keywords-suggestion-item,
.toolbar-body-reload {
    cursor: pointer;
    transition: 0.15s all ease-in-out;
}
/* TODO trash */
.keywords-suggestion-item:hover,
.toolbar-body-reload:hover {
    opacity: 0.7;
}


.new-sidebar-layout {
    display: flex;
    align-items: flex-start;
    min-height: 100dvh;
}
.new-sidebar-layout .content-box-header {
    border-radius: 0;
    background: transparent;
    border: 0;
}
.new-sidebar-layout .toolbar-sidebar {
    max-width: 350px;
    width: 100%;
}
.toolbar-sidebar-nav {
    max-width: 72px;
    width: 100%;
    background: #151515;
}
.toolbar-sidebar-nav .sidebar-logo {
    height: 60px;
    padding: 19px;
    display: block;
    border-right: 1px solid #1F1F1F;
    background: #111;
    transition: 0s;
}
.toolbar-sidebar-nav-link  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    min-height: 72px;
    border-right: 1px solid #1F1F1F;
    background: #111;
    border-top: 1px solid #111;
    border-bottom: 1px solid #111;
    transition: 0s;
}
.toolbar-sidebar-nav-link .icon-wrpr {
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toolbar-sidebar-nav-link.active .icon-wrpr {
    color: #FF8114;
}
.toolbar-sidebar-nav-link.active {
    border-right: 0;
    background: #151515;
}
.toolbar-sidebar-nav-link.active+.toolbar-sidebar-nav-link {
    border-top: 1px solid #1F1F1F;
    border-radius: 0px 12px 0px 0px;
}
.toolbar-sidebar-nav .sidebar-logo.pre-active,
.toolbar-sidebar-nav-link.pre-active {
    border-bottom: 1px solid #1F1F1F;
    border-radius: 0px 0px 12px 0px;
}

.saved-content-actions {
    z-index: 2;
    border-radius: 360px;
    background: #1B1B1B;
    width: 28px;
    height: 28px;
    float: right;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 10px;
    right: 10px;
}

.action-item-dropdown {
    position: absolute;
    z-index: 10;
    top: calc(100% + 8px);
    right: 0;

    width: 100px;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.action-item-dropdown-item {
    display: flex;
    height: 28px;
    padding: 11px 10px;
    align-items: center;
    border-radius: 8px;
    background: #1B1B1B;

    opacity: 0.9;
    color: #A7A7A7;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;

    transition: color 0.3s ease;
}

.action-item-dropdown-item:hover {
    color: #FFFFFF;
}

.action-item-dropdown-divider {
    width: 72px;
    height: 1px;
    background: #333;
    margin: 4px auto;
}
.left-content-action {
    right: unset;
    left: 0;

    border: 1px solid rgba(255, 255, 255, 0.10);

}

.saved-content-item-category-label {
    color: #A7A7A7;
    font-size: 11px;
    font-weight: 300;

    margin-left: 10px;
    width: max-content;
    max-height: 24px;

    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;

    border-radius: 360px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #232323;
}

.brand-voice-img {
    width: 130px;
    height: 180px;
    min-width: unset !important;
}

.saved-content-item:has(.brand-voice-img) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions-assets-panel-item {
    display: flex;
    height: 36px;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    margin-right: 8px;
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
    transition: 0.3s all ease-in-out;
}

.actions-assets-panel-item svg {
    margin-right: 8px;
}

.actions-assets-panel-item:last-child {
    margin-left: auto;
}

.actions-assets-panel-item:hover {
    background: radial-gradient(87.42% 71.88% at 50% 121.88%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%), #1B1B1B;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.selected-content-item {
    border: 1px solid #FF8114 !important;
}

.select-ico {
    display: none;
}

.selected-content-item .no-select-ico {
    display: none;
}

.selected-content-item .select-ico {
    display: block;
}

.selected-content-item .left-content-action {
    background: #FF8114;
}

.modal__wrap {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    display: flex;
    flex-direction: column;
    width: 572px;

    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
}

.modal-top {
    display: flex;
    padding: 16px;
}

.close-modal-block {
    display: flex;
    width: 34px;
    height: 34px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    z-index: 2;
}
.close-modal-block:hover {
    border: 1px solid #fdfbfb;
}
.close-modal-block:hover svg path{
    stroke: #FFFFFF;
}

.modal-body {
    flex: 1;
    display: flex;
    padding: 0px 36px 36px 36px;
    flex-direction: column;
}

.modal-body-title {
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    opacity: 0.9;
    margin-bottom: 20px;
}

.modal-body-subtitle {
    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    opacity: 0.5;

    margin-bottom: 36px;
}

.modal-component {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin-bottom: 16px;
}

.modal-component.drag-area {
    margin-bottom: 0px;
}
.modal-component-label {
    color: #A7A7A7;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 10px;
}

.drop-file-area {
    display: flex;
    width: 500px;
    height: 100px;
    padding: 16px 8px 16px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed #FF8114;
}

.drop-file-area span {
    color: #FF8114;
    font-size: 14px;
    font-weight: 400;
}

.modal-actions {
    display: flex;
    margin-top: 36px;
}

.modal-primary-btn {
    margin-left: auto;
    display: flex;
    height: 40px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;

    color: #331A04;
    font-size: 14px;
    font-weight: 600;
}

/* NEW SETTINGS */


.settings-component-wrapper {
    padding: 30px 0;
    display: flex;
    width: 100%;
}

.settings-component-side {
    width: 50%;
    display: flex;
}

.settings-component-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.settings-component-subtitle {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.4;
}
.settings-component-avatar {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    overflow: hidden;
    margin-right: 30px;
}

.settings-divider {
    height: 1px;
    background: #1F1F1F;
    margin: 0 20px;
}

.btn-outline-primary, .btn-outline-primary-fill {
    display: inline-flex;
    height: 40px;
    padding: 11px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    border: 1px solid #FF8114;

    color: #FF8114;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.btn-outline-primary svg, .btn-outline-primary-fill svg {
    margin-right: 8px;
}

.btn-outline-primary-fill {
    font-weight: 600;
    border: unset;
    color: #331A04;
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
}

.settings-action {
    display: flex;
    padding: 20px;
}

.settings-checkbox-container {
    display: flex;
    align-items: center;
}

.sett-checkbox {
    margin-right: 24px;
}

.sett-checkbox-sub {
    position: relative;
    display: flex;
    width: 40px;
    height: 24px;
    padding: 3px;
    align-items: center;
    border-radius: 360px;
    background: #282828;
}

.sett-checkbox-sub:before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4px;
    bottom: 3px;
    border-radius: 360px;
    background: #A7A7A7;
    box-shadow: 1px -1px 2px 0px rgba(255, 255, 255, 0.36) inset;
    transition: .4s;
}

.sett-checkbox-hid:checked+.sett-checkbox-sub {
    background: #FF8114;
}

.sett-checkbox-hid:checked+.sett-checkbox-sub:before {
    transform: translateX(14px);
    background: #151515;
    box-shadow: 1px -1px 2px 0px rgba(255, 255, 255, 0.36) inset;
}

.sett-checkbox-desc {
    display: flex;
    flex-direction: column;
}

.sett-title {
    font-size: 16px;
    font-weight: 500;
}

.sett-checkbox-desc-sub-title {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.4;
}
.settings-starter {
    display: flex;
    padding: 14px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 16px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.settings-starter-head {
    display: flex;
    align-items: center;
    width: 100%;
}
.settings-starter-price {
    margin-left: auto;
}

.settings-starter-price-title {
    font-size: 32px;
    font-weight: 500;
}

.payment-card-container {
    display: flex;
    padding: 14px 16px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.payment-card-container.active {
    border: 1px solid #FF8114;
    background: #29190B;
}

.payment-card-container-img {
    width: 46px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
}

.payment-card-container-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.input-copy-text {
    position: absolute;
    color: #FF8114;
    font-size: 14px;
    font-weight: 500;
    right: 10px;
    top: 57%;
    transform: translate(0, -50%);
}

.input-with-btn {
    padding: 8px 85px 8px 14px;
}

.refferal-statistic {
    width: 100%;
    display: flex;
    padding: 14px 16px;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #333;
    background: #1B1B1B;
}
.refferal-statistic:not(:last-child) {
    margin-right: 15px;
}
.refferal-statistic-title {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.4;
    margin-bottom: 12px;
}

.custom-checkbox-text.default-collor {
    color: #A7A7A7 !important;
}
.custom-checkbox-settings .custom-checkbox-fake{
    width: 18px;
    height: 18px;
    flex: unset;
}

.card-input {
    padding: 8px 14px 8px 50px;
}

.card-inp-image {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, 34%);
}
.modal-component.two-elements {
    flex-direction: row;
}

/* generator v4 */

.gen4-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    padding: 16px 0;
}
.gen4-backline {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: #111;
}
.gen4-backline svg {
    opacity: 0.05;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(135%);
}
.gen4-main-block {
    z-index: 2;
    display: flex;
    flex: 1;
    max-width: 658px;
    height: 100%;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
}
.gen4-header {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #1F1F1F;
}
.gen4-header-part {
    display: flex;
    align-items: center;
}
.gen4-header-select-container p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    opacity: 0.4;
}
.gen4-header-select-container h3 {
    font-size: 14px;
    font-weight: 500;
}
.gen4-theme-switcher {
    cursor: pointer;
    display: flex;
    padding: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    background: #1B1B1B;
}

.gen4-theme-part {
    display: flex;
    height: 28px;
    padding: 11px 12px 11px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    background: #1B1B1B;
}
.gen4-theme-part span {
    color: #FFFFFF;

    font-size: 13px;
    font-weight: 500;
    opacity: 0.3;
}
.gen4-theme-part svg {
    margin-right: 8px;
    opacity: 0.3;
}
.gen4-theme-part.active {
    background: #2B2B2B;
}
.gen4-theme-part.active span {
    color: rgba(255, 255, 255, 0.60);
    opacity: 1;
}
.gen4-theme-part.active svg {
    opacity: 0.6;
}
.gen4-header-actions {
    display: flex;
    width: 34px;
    height: 34px;
    padding: 8px;

    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.gen4-toolkit-wrapper {
    /*position: absolute;*/
    z-index: 1;
    max-width: 350px;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    flex: 1 1;
    height: 100%;
    /* transform: translateX(-250px); */
    /*margin: 16px 0;*/
    /*height: calc(100% - 32px);*/
}

.gen4-toolkit-header {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
}
.gen4-toolkit-header span {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    opacity: 0.9;
}
.gen4-toolkit-close {
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}
.gen4-toolkit-close, .gen4-toolkit-close svg path, .gen4-toolkit-close svg g {
    transition: all 0.3s ease;
}
.gen4-toolkit-close:hover {
    border: 1px solid white;
}
.gen4-toolkit-close:hover svg path {
    stroke: #FFFFFF;
}
.gen4-toolkit-close:hover svg g {
    opacity: 1;
}

.toolkit-open {
    border: 1px solid #FF8114;
}
.toolkit-open svg path {
    fill: #FF8114;
}
.toolkit-open svg g {
    opacity: 1;
}

.gen4-main-block {
    animation: gen4-anim-back 0.5s ease;
    transform: translateX(184px);
}

.gen4-main-block.gen4-side-open {
    animation: gen4-anim 0.5s ease;
    transform: translateX(0px);
}

.gen4-toolkit-wrapper {
    margin-left: 16px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.gen4-toolkit-wrapper.gen4-side-open {
    display: block;
    position: relative;
    opacity: 1;
}

@keyframes gen4-anim {
    0% {
        transform: translateX(200px);
    }
    60% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes gen4-anim-back {
    0% {
        transform: translateX(0px);
    }
    60% {
        transform: translateX(210px);
    }
    100% {
        transform: translateX(200px);
    }
}

.gen4-content {
    display: flex;
    flex-direction: column;
    padding: 36px 48px;
    flex: 1;
    overflow: auto;
}
.gen4-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.get4-content-header {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
}
.get4-content-title {
    font-size: 24px;
    font-weight: 500;
    opacity: 0.9;
    margin-bottom: 20px;
}
.get4-content-subtitle {
    color: #A7A7A7;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    opacity: 0.5;
}

.gen4-footer {
    display: flex;
    padding: 16px;
    border-top: 1px solid #1F1F1F;
}

.gen4-btn {
    display: flex;
    height: 44px;
    padding: 8px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    margin: 0 auto;
}
.gen4-btn:disabled {
    background: #1B1B1B;
}

.sign-up-btn:disabled {
    background: #ffffff08;
    color: #A7A7A7;
}

.gen4-btn span {
    font-size: 14px;
    font-weight: 600;
    color: #331A04;
    margin-right: 8px;
}

.gen4-btn:disabled span {
    color: #FFFFFF;
    opacity: 0.3;
}

.gen4-btn:disabled svg {
    opacity: 0.3;
}
.gen4-btn:disabled svg path {
    fill: white;
}

.height-40 {
    max-height: 40px;
}

/* input suggestion */

.inp-sugg-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gen4-input-suggestion {
    cursor: pointer;
    display: flex;
    padding: 5px 6px 5px 10px;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #383838;
    background: #202020;
    width: max-content;


    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.gen4-input-suggestion:not(.selected-i-sugg):hover {
    color: #FFFFFF;
    opacity: 1;
}


.gen4-input-suggestion svg {
    margin-left: 6px;
}

.selected-i-sugg {
    border: 1px solid #FF8114;
    color: #FF8114;
    opacity: 1;
}
.i-s-ico-selected {
    display: none;
}

.gen4-input-suggestion.selected-i-sugg .i-s-ico {
    display: none;
}
.gen4-input-suggestion.selected-i-sugg .i-s-ico-selected {
    display: block;
}

.accordion.inp-sugg {
    transition: height 0.1s ease-in-out;
}

/* oneline square blocks */
.oneline-square-item {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    height: 44px;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #202020;
    flex: 1;
    /* margin-right: 5px; */

    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.50);
    font-size: 13px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.oneline-square-item-li.one {
    margin-bottom: 8px;
}
.oneline-square-item-li.two {
    margin-right: 5px;
    flex: 1;
}

.oneline-square-item-li.two:last-child {
    margin-right: 0;
}

.oneline-square-item:not(.active):hover {
    border: 1px solid rgba(255, 255, 255, 0.80);
    color: rgba(255, 255, 255, 0.80);
}

.oneline-square-item.active {
    color: #FF8114;
    border: 1px solid #FF8114;
}

.oneline-square-suggestions {
    display: flex;
    flex-wrap: wrap;
}

.oneline-square-suggestions li {
    margin-right: 8px;
}

.blog-config-tabs {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #1F1F1F;
}
.blog-config-tabs li {
    flex: 1;
}

/* keywords generator */

.keywords-gen-options {
    display: flex;
    flex-wrap: wrap;
}

.keywords-gen-opt-item {
    margin-right: 6px;
}

.keywords-gen-opt-item:hover svg path {
    fill-opacity: 1;
}
.keyword-sugg-list {
    display: flex;
    flex-wrap: wrap;
}
.keyword-sugg-list li {
    display: flex;
    cursor: pointer;
    min-height: 26px;
    padding: 5px 10px;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 5px;

    font-size: 12px;
    font-weight: 500;

    border-radius: 24px;
    background: #202020;

    color: rgba(255, 255, 255, 0.50);
    transition: all 0.3s ease;
}

.keyword-sugg-list li.used {
    pointer-events: none;
    text-decoration: line-through;
}

.keyword-sugg-list li:not(.used):hover {
    color: rgba(255, 255, 255);
}

/* setting generator */

.generator-settings-part {
    display: flex;
}

.call-from-generate .generator-settings-part {
    flex-direction: column;
}
.call-from-generate .generator-settings-part .gen4-settings-select{
    margin-bottom: 8px;
}

.input-custom__wrap-input.gen4-settings-select .select-selection {
    min-height: 40px !important;
    height: 40px !important;
}

.input-custom__wrap-input.gen4-settings-select .select-selection .aaa {
    font-weight: 400 !important;
}

.input-custom__wrap-input.gen4-settings-select .select-selection .select-selection-arrow {
    padding: 0 8px 0 0;
}

.input-custom__wrap-input.gen4-settings-select .number-input input {
    height: 40px;
}

/* Content Box new */

.content-box-footer-save {
    padding: 9px 14px !important;
}

.gen-con-description-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 42px;
    overflow: hidden;
    
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;

    transition: height 0.3s ease;
}

.gen-con-description-wrap.hide-gen-con {
    display: none;
}

.gen-con-description-wrap.open {
    height: 442px;
}

.gen-header-arrow {
    cursor: pointer;
    transition: all 0.3s ease;
}

.gen-con-description-wrap.open .gen-header-arrow {
    transform: rotate(180deg);
}

.gen-con-desc-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 11px 16px;
    height: 42px;
}

.gen-con-header-title {
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.9;
}

.gen-con-header-date {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.3;
}

.gen-con-body-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: 0 16px 16px 16px;
}

.gen-con-body-panel {
    display: flex;
    align-items: center;
}

.gen-con-panel-item {
    cursor: pointer;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;

    margin-right: 8px;


    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.gen-con-panel-item.active {
    background: #FF8114;
    color: #331A04;
}
.gen-con-panel-item.active:not(.another-fill) svg path {
    fill: #331A04;
}
.gen-con-panel-item.active.another-fill svg path {
    stroke: #331A04;
}

.gen-con-panel-item:hover {
    border: 1px solid white;
}

.gen-con-panel-divider {
    width: 1px;
    height: 18px;
    background: #1F1F1F;
    margin-right: 8px;
}

.gen-con-panel-item.round {
    width: auto;
    padding: 0 11px;

    font-size: 12px;
    font-weight: 500;

    border-radius: 360px;
}

.gen-con-body-content {
    margin: 24px auto 12px;
    height: 100%;
    width: 100%;
    max-width: 850px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gen-con-textarea {
    height: 100%;
    padding: 0 !important;
    border-radius: unset;
    border: none;
    background: unset;
}

.gen-con-body-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gen-con-variants {
    display: flex;
    align-items: center;
}

.gen-con-variants li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 11px 10px;
    border-radius: 8px;
    border: 1px solid #333;
    margin-right: 8px;

    color: #A7A7A7;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.9;
}

.gen-con-variants li:not(.active):hover {
    color: #fff;
    border: 1px solid #fff;
}
.gen-con-variants li.active {
    color: #fff;
    border: 1px solid #FF8114;
}

.box-product-container.full-size {
    height: calc(100% - 63px);
}

.gen-con-description-wrap.full-size, .gen-con-description-wrap.full-size .gen-con-body-wrap {
    height: 100%;
}

.gen-con-description-wrap.full-size .gen-con-body-content {
    flex: 1;
}


/* new words */

.checkbox-new-words {
    padding: 0 10px;
}

.checkbox-new-words .sett-checkbox {
    margin-right: 0px;
    margin-left: 8px;
}

.checkbox-new-words .sett-title {
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}

/* new words card */

.card-cust{
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 16px;
    border: 1px solid #333;
    background: #1B1B1B;
}
.card-cust:not(.w-full):hover {
    border: 1px solid white;
}
.card-cust--header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.card-cust--body{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.ul-points{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ul-points > .ul-points--item:not(:first-child)::before{
    content: "\2022";
    opacity: 0.3;
    color: #D9D9D9;
    margin-right: 3px;
}
.ul-points > .ul-points--item:not(:last-child){
    margin-right: 3px;
}
.ul-points--item{
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
}

.comment-block{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.comment-block .comment-footer{
    margin-bottom: -10px;
    margin-top: 16px;
}
.comment-text{
    color: rgba(255, 255, 255, 0.80);
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    line-height: 17px;
}
.comment-body{
    flex: 1;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid #252525;
    border-bottom: 1px solid #252525;
}
.comment-footer-user{
    white-space:nowrap;
    overflow: hidden;
}
.comment-footer-user--text{
    overflow: hidden;
    text-overflow: ellipsis;
}
.comment-footer > *:not(:last-child){
    margin-right: 10px;
}
.comment-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}
.comment-block .comment-footer > *{
    padding-bottom: 10px;
}
.user{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 300px;
    background: #252525;
}
.user-20{
    min-width: 20px;
    max-width: 20px;
    height: 20px;
}
.user-36{
    min-width: 36px;
    max-width: 36px;
    height: 36px;
}
.user > img{
    width: 100%;
    height: 100%;
    position: absolute;
}

.widget-finder{
    display: flex;
    flex-direction: column;
}
.widget-finder--header{
    display: flex;
}
.card-cust--row{
    flex-direction: row;
}
.card-cust--row .comment-body{
    border: none;
}
.card-cust--row .card-cust--body{
    border-left: 1px solid #252525;
    max-width: 200px;
}



/* https://prnt.sc/outtHPcbfp3L */
/* .swiper-1 .swiper-button-prev{display:none;}
.swiper-1::after{
    content: "";
    width: 70px;
    height: 100%;
    display: block;
    top: 0;
    position: absolute;
    z-index: 2;
}



.swiper-1 .swiper-button-next:after, 
.swiper-1 .swiper-rtl .swiper-button-next:after{
    content: "";
    background: url("../img/arrow-primary--next.svg") no-repeat center center / 36px;
    width: 36px;
    height: 36px;
}
.swiper-1 .swiper-button-prev:after, 
.swiper-1 .swiper-rtl .swiper-button-prev:after{
    content: "";
    background: url("../img/arrow-primary--next.svg") no-repeat center center / 36px;
    width: 36px;
    height: 36px;
    transform: rotate(180deg);
} */

.swiper-1 .swiper-button-next.swiper-button-disabled, 
.swiper-1 .swiper-button-prev.swiper-button-disabled{
    opacity: 0 !important;

}

.swiper-small::after{
    content: "";
    width: 70px;
    height: 100%;
    display: block;
    top: 0;
    position: absolute;
    z-index: 2;
    right: 0;
    background: linear-gradient(270deg, #151515 4.29%, rgba(21, 21, 21, 0.00) 100%);
}

/*   */
.photo-card{
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #333;
}

.photo-card.md {
    width: 160px;
    height: 90px;
    font-size: 13px;
}

.photo-card.sm {
    width: 130px;
    height: 80px;
    font-size: 12px;
}

.photo-card--content{
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: 0;
    padding: 12px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.photo-card img{height:100%;width:100%;}
.photo-card::after{
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background: linear-gradient(180deg, rgba(21, 21, 21, 0.00) 0%, #151515 100%);
}
.or-row{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}
.or-row::after{
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    height: 1px;
    background-color: #1F1F1F;
}
.gen4-main-block .or-text{
    background-color: #151515;
}
.or-text{
    color: #A7A7A7;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding-left: 12px;
    padding-right: 12px;
    z-index: 1;
}
.or-row-my{
    margin-top: 16px;
    margin-bottom: 16px;
}
.widget-area{
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #262626;
    background-color: #1B1B1B;
    border-radius: 8px;
}
.textarea::-webkit-scrollbar{
    display: none;
}
.widget-area .textarea{
    border: none;
    background-color: transparent;
    border-radius: 0;
    padding: 14px;
}
.widget-area .widget-area--actions{
    padding: 14px;
}
.gen4-footer{justify-content:center;}
.gen4-footer > *{
    margin: 0px 5px !important;
}
.gen4-btn.outline-btn{
    background: transparent;
    border: 1px solid #FF8114;
    color: #FF8114;
}
.gen4-btn.outline-btn:hover{
    color: #000;
    background-color: #FF8114;
}
.gen4-btn.outline-btn:hover *{
    color: #000;
}
.gen4-btn.outline-btn *{
    color: #FF8114;
}
[disabled]{pointer-events:none;}

.widget-area--actions [disabled]{
    background: transparent;
    color: rgba(256, 256, 256, 0.3);
    border-color: #333;
}
.btn--outline-tansparent{
    border: 1px solid #333;
    background: #1B1B1B;
    color: #767676;
}
.btn--sm{
    padding: 5px 12px;
    min-height: 32px;
}
.input-container--heading{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -5px;
}
.input-container--heading > *{
    margin-bottom: 5px;
}
.input-container--heading > *:not(:last-child){
    margin-right: 10px;
}
.topic-list, .topic-item{
    display: flex;
    flex-direction: column;
}
.topic{
    display: flex;
    align-items: center;
}
.topic > *:not(:last-child){
    margin-right: 10px;
}
.topic-list .topic-item:not(:last-child){
    margin-bottom: 10px;
}
.input-container{
    display: flex;
    flex-direction: column;
}
.select-40{min-height:40px;}
.select-36{min-height:36px;}
/*
.btn-outline-black .info{
    color: #fff;
}*/
.list-sliders{
    display: flex;
    flex-direction: column;
}
.list-sliders .slide-li:not(:last-child){
    margin-bottom: 16px;
}
.form-40{height:40px;}
/* https://prnt.sc/outtHPcbfp3L */





/* https://prnt.sc/nOgPg_4PGZ6C */
.photo-card--active.photo-card{
    border-color: #FF8114;
}
.photo-card--active.photo-card .photo-card--content{
    color: #FF8114;
}
/* https://prnt.sc/nOgPg_4PGZ6C */



/* https://prnt.sc/hyFH02TB2Ap0 */
.card-black-st{
    border: 1px solid #1F1F1F;
    background: #151515;
    display: flex;
}
.wr-img{
    overflow: hidden;

}
.wr-img--160{
    max-width: 160px;
    min-width: 160px;
}
.wr-img img{
    width: 100%;
    height: 100%;
}
.wr-img-styles{
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 0px 4px 8px 0px #111, 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
}
.card-body{
    display: flex;
    flex-direction: column;
}
.itesm--20 > *:not(:last-child){
    margin-bottom: 20px;
}
.card-16{
    padding-top: 10px;
}
.card-16{
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.card-16 > *{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 16px;
    margin-bottom: -16px;
}
.row-options{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -10px;
}
.row-options .row-option{
    margin-bottom: 10px;
}
.row-options .row-option:not(:last-child){
    margin-right: 10px;
}
.dbl-btns--8 > *:not(:last-child){
    margin-right: 8px;
}
.btn--32{
    min-width: 32px;
    max-width: 32px;
    height: 32px;
}
.card-spacing-y-13 > .card:not(:last-child){
    margin-bottom: 13px;
}
.widget-conntect{
    min-height: 56px;
    padding: 12px;
    text-align: center;
    position: relative;
}
.widget-conntect .widget-conntect--ico ~ .widget-conntect--text{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: flex-start;
}
.widget-conntect .widget-conntect--ico{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
}



/* https://prnt.sc/Lya_kFCk7aGw */
.editor-container{
    color: #fff;
    font-family: var(--fotnFamily);
    display: flex;
    flex-direction: column;
}
.editor-pills{
    display: flex;
    margin-bottom: -12px;
    flex-wrap: wrap;
}
.editor-pills li{
    margin-right: 6px;
    margin-bottom: 6px;
}
.btn--25{
    min-width: 25px;
    max-width: 25px;
    height: 25px;
}
.pill-btn{
    min-height: 25px;
    display: flex;
    align-items: center;
    border-radius: 360px;
    border: 1px solid #333;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    padding: 3px 10px;
}
.pill-btn.px-\[9px\] {
    padding-left: 9px;
    padding-right: 9px;
}
.editor-container > *:not(:last-child){
    margin-bottom: 30px;
}
.editor-container .editor-pills{
    margin-bottom: 12px !important;
}
.editor-container img{
    max-height: 300px;
    border-radius: 12px;
}
.editor-container p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #A7A7A7;
    opacity: 0.6;
}
.editor-container h1{
    font-weight: 500;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px !important;
}
.editor-container h2{
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px !important;
}
/* https://prnt.sc/Lya_kFCk7aGw */



/* https://prnt.sc/VrNZD2bCKtOU */
.card-column{
    flex-direction: column;
}
.card-16.card-column .card-header{
    margin-bottom: 0px;
}
.itesm--8 > *:not(:last-child){
    margin-bottom: 8px;
}
/* https://prnt.sc/VrNZD2bCKtOU */



.ul-sec{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -5px;
}
.ul-sec > .ul-sec--item{margin-bottom:5px;}
.ul-sec > .ul-sec--item:not(:last-child){
    margin-right: 12px;
}
.btn-outline-black2{
    border: 1px solid #333;
    background: #1B1B1B;    
    color: rgba(255, 255, 255, 0.50);
}

.custom-counter--list{
    counter-reset: section;
}
.custom-counter--list li::before{
    counter-increment: section;
    content: counter(section) ". ";
    margin-right: 5px;
}
.custom-counter--item{
    display: flex;
}
.custom-counter--item + .custom-counter--item{
    margin-top: 12px;
}

.new-word-divider {
    margin: 24px 0;
}

.swiper-custom-wrap {
    width: 100%;
    position: relative;
}

.big-swiper .swiper-slide{
    padding-left: 55px;
    padding-right: 55px;
}

.swiper-arrow-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%);
}

.swiper-arrow-button-next {
    right: 0;
}

.big-asset-swiper .swiper-arrow-button {
    left: 30px;
}
.big-asset-swiper .swiper-arrow-button:hover {
    opacity: 0.7;
}
.big-asset-swiper .swiper-arrow-button.swiper-arrow-button-next {
    left: auto;
    right: 30px;
}

.result-widget--body * {
    flex: unset;
}

.gen4-tabs {

}

.gen4-tabs ul::before {
    display: none;
}
.gen4-tabs ul {
    padding: 0;
}

.swiper-small {
    position: relative;
}
.swiper-small .swiper-slide {
    width: auto;
}

.swiper-button-disabled.swiper-arrow-button-next {
    display: none;
}

.react-datepicker {
    font-family: inherit;
    font-size: 14px;
    color: white;
    border-radius: 8px;
    border: 1px solid #383838;
    background: #202020;
    margin-top: 10px;
}

.react-datepicker-wrapper {
    width: calc(100% - 20px);
}

.react-datepicker__day {
    color: white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 4px;
    box-sizing: border-box;
}
.react-datepicker__day:hover {
    background: #FF8114;
}

.react-datepicker__header {
    background: #202020;
    padding: 10px 0 0;
}

.react-datepicker__current-month, .react-datepicker__day-names {
    color: #A7A7A7;
    font-size: 16px;
    margin: 0;
}

.react-datepicker__day-name {
    font-size: 16px;
    color: white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 4px;
    box-sizing: border-box;
}

.react-datepicker__month {
    margin: 0;
}

.react-datepicker__day--today {
    font-weight: normal;
}

.react-datepicker__triangle::after, .react-datepicker__triangle::before {
    display: none;
}

.react-datepicker__input-container input {
    background: inherit;
}

.react-datepicker__navigation {
    top: 10px;
}

.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
    background: #9CA3AF;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--selected:hover {
    background: #FF8114;
}

.react-datepicker__time-container {
    width: 280px;
}

.react-datepicker-time__header {
    color: inherit;
    font-size: inherit;
    margin-bottom: 10px;
}

.react-datepicker__time-container .react-datepicker__time {
    background: inherit;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: auto;
}

.react-datepicker__time-list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: #FF8114;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #FF8114;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #FF8114;
    color: white;
    font-weight: bold;
}

.goal-tabs ul {
    padding: 0;
}
.goal-tabs ul::before {
    display: none;
}

.goal-generator-icon {
    width: 18px;
    height: 18px;
}

.goal-generator-button:hover, .goal-generator-button-plus:hover, .goal-generator-button-edit:hover {
    background: #151515;
}

.goal-generator-button {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #A7A7A7;
    width: 28px;
    border-radius: 6px;
    position: relative;
}

.goal-generator-button-label {
    width: auto;
    padding: 0 10px;
}

.goal-generator-button-edit {
    height: 32px;
    background: #1B1B1B;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333333;
    gap: 6px;
    font-size: 13px;
    font-weight: 400;
    color: #A7A7A7;
    border-radius: 8px;
    padding: 0 12px;
    border-radius: 360px;
}

.goal-generator-button-edit.save {
    background: #FF8114;
    color: #331A04;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    border: none;
}

.goal-generator-button-awesome:hover {
    border-top: 1px solid rgba(255, 255, 255, 0.60);
    background: radial-gradient(79.69% 79.69% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), #FF8114;
}

.goal-generator-button-awesome {
    border-radius: 360px;
    background: #FF8114;
}

.goal-generator-button-active {
    border-color: #FF8114;
    color: #FF8114;
    background: rgba(255, 129, 20, 0.10);
}

.goal-generator-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: rgba(21, 21, 21, 0.80);
    backdrop-filter: blur(9px);
    width: 298px;
    padding: 24px;
    z-index: 1;
}

.goal-generator-divider {
    width: 1px;
    height: 18px;
    background: #1F1F1F;
}

.goal-generator-button-plus {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    border: 1px solid #383838;
    background: #1B1B1B;
}

.goal-add-task {
    border-radius: 360px;
    border: 1px solid #383838;
    background: #1B1B1B;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #A7A7A7;
}
.goal-add-task:hover {
    background: radial-gradient(87.42% 71.88% at 50% 121.88%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%), #1B1B1B;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.card-drag-btn.disabled,
.goal-add-task.disabled,
.goal-generator-button.disabled,
.cursor-pointer.disabled,
.gen-con-header-title.disabled,
.block-disabled {
    pointer-events: none;
    opacity: .65;
}

.goal-add-task.add-sub-task {
    height: 26px;
    font-size: 12px;
    line-height: normal;
}

.share-item {
    flex: 1 1;
    margin: 0;
}

.modal-body-flex {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.goal-generator-card {
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    padding: 0 16px;
    margin-bottom: 12px;
}

.goal-generator-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 0;
}

.goal-generator-card-content {
    padding: 16px;
}

.accordion-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease;
}

.accordion-content[aria-hidden="false"] {
    grid-template-rows: 1fr;
}

.generator-card-loader-item {
    height: 10px;
    border-radius: 350px;
    background: #1B1B1B;
}

.goal-generator-action {
    border-radius: 360px;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    height: 40px;
    padding: 0 14px;
    justify-content: center;
    align-items: center;
    color: #331A04;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
}

.goal-generator-input {
    height: 40px;
    padding: 8px 8px 8px 14px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    font-size: 14px;
}

.generator-button-tooltip {
    border-radius: 8px;
    background: #111;
}

.generator-button-tooltip .tippy-content {
    padding: 8px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    color: #A7A7A7;
}

.generator-button-tooltip-modal {
    border-radius: 8px;
    background: #1B1B1B;
    border: 1px solid #333;
    pointer-events: all;
}

.generator-button-tooltip-modal .tippy-content {
    padding: 4px;
}

.goal-generator-button-cross {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 360px;
    border: 1px solid #262626;
    background: #1B1B1B;
}

.awesome-sugg {
    width: 14px;
    height: 14px;
}

.awesome-sugg path {
    fill: #FF8114;
}

.goal-generator-label-cross {
    width: 16px;
    height: 16px;
}

.goal-generator-label-cross path {
    stroke: #FF8114;
}

.chat-bot-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.36);
    background: radial-gradient(72.22% 31.25% at 50% 94.79%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(135deg, #515151 0%, #A4A4A4 49.48%, #515151 100%);
    box-shadow: 0px -6px 6px 0px rgba(255, 255, 255, 0.70) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.36);
}

.chat-bot {
    height: calc(100vh - 98px);
    width: 400px;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #111;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.36);
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.chat-bot-user-img {
    width: 32px;
    height: 32px;
    border-radius: 8px;
}

.chat-bot-chat {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 0;
    overflow-y: auto;
}

.chat-bot-card {
    padding: 12px 16px;
    border: 1px solid #1F1F1F;
    border-radius: 16px;
    display: flex;
    gap: 14px;
}

.chat-bot-text {
    color: #FFF;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.6;
    flex: 1 0;
}

.chat-bot-input-container {
    margin: 12px 0;
    padding: 8px 12px 8px 16px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    height: 40px;
    display: flex;
    align-items: center;
}

.chat-bot-input {
    background: inherit;
    font-size: 14px;
    flex: 1 0;
}

.chat-bot-header-button {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.generator-form-button {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.selected-button {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 129, 20, 0.10);
}

.overflow-visible {
    overflow: visible !important;
}

.generated-tab {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background: #111111;
    border: 1px solid #111111;
    border-right-color: #1F1F1F;
}

.generated-exit-wrapper {
    flex: auto;
    display: flex;
    align-items: flex-end;
    border-right: 1px solid #1F1F1F;
    border-top: 1px solid #111;
    background: #111;
}

button.generated-tab > svg, .generated-tab span {
    opacity: 0.4;
}

.generated-tab--active {
    background: #151515;
    border-color: #151515;;
}

button.generated-tab--active > svg {
    opacity: 1;
}

.generated-tab--active > svg > * {
    stroke: #FF8114;
}

.generated-tab--active span {
    opacity: 0.9;
}

.generated-tab--active-top {
    border-radius: 0px 0px 12px 0px;
    border-right: 1px solid #1F1F1F;
    border-bottom: 1px solid #1F1F1F;
}

.generated-tab--active-bottom {
    border-radius: 0px 12px 0 0px;
    border-right: 1px solid #1F1F1F;
    border-top: 1px solid #1F1F1F;
}

.generated-sidebar {
    width: 350px;
    height: 100%;
    background: #151515;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    border-right: 1px solid #1F1F1F;
    display: flex;
    flex-direction: column;
}

.generated-sidebar > div {
    width: 350px;
}

.generated-sidebar--closed {
    width: 0px;
}

.generated-tab--closed {
    border-color: #111;
}

.generated-button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 360px;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #331A04;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}

.generated-button.small {
    width: 40px;
    border-radius: 8px;
}

.input-search {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    padding: 8px;
}

.input-search input {
    background: inherit;
    flex: 1;
    font-size: 14px;
}

.generated-user-card {
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    display: flex;
    padding: 12px 8px 12px 14px;
    justify-content: space-between;
    align-items: center;
}

.generated-service-card {
    display: flex;
    width: 154px;
    height: 56px;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.generated-service-img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 360px;
}

.generated-content-bottom-panel {
    display: flex;
    padding: 13px 16px 13px 20px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #1F1F1F;
    background: rgba(17, 17, 17, 0.80);
    backdrop-filter: blur(25px);
}

.generated-content-bottom-panel-delete {
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 360px;
    border: 1px solid #262626;
    background: #1B1B1B;
}

.generated-content-bottom-panel-btn {
    width: auto;
    padding: 0 14px;
}

.generated-content-letter-card {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    display: flex;
    gap: 20px;
}

.generated-content-letter-card-image {
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 0px 4px 8px 0px #111, 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
}

.generated-content-letter-card-button {
    height: 32px;
    border: 1px solid #333;
    background: #1B1B1B;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #A7A7A7;
    font-size: 13px;
}

.no-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.generated-content-letter-card-full {
    flex: 1 1 0%;
    margin: 4px 16px 12px 16px;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    padding: 32px 100px;
}

.email-newsletter-container {
    width: 100%;
    height: calc(100vh - 84px);
    margin: 20px;
    margin-top: 0;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    overflow: auto;
}

.email-newsletter-btn {
    width: auto;
    height: 36px;
    padding: 0 14px 0 10px;
    font-weight: 500px;
}

.email-select .select-selection {
    min-height: 36px !important;
    height: 36px !important;
}

.email-select .select-selection-arrow {
    margin-right: -10px;
}

.default-select.sort-select-box.small-select.email-select.email-sort .aaa {
    font-weight: 500 !important;
    color: #fff !important;
    opacity: 0.5;
}

.generated-email-title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    height: 44px;
}

.generated-email-paragraph {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.80);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px; /* 141.667% */
}

.chat-bot-gradient {
    background: linear-gradient(270deg, #1B1B1B 53.93%, rgba(27, 27, 27, 0.00) 100%);
}

.blog-generator-button {
    border-radius: 6px;
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.blog-generator-button:hover {
    background: #151515;
}

.blog-generator-button:disabled {
    opacity: 0.4;
}

.blog-generator-button.awesome {
    border-radius: 360px;
    background: #FF8114;
}

.blog-generator-button.awesome:hover {
    border-top: 1px solid rgba(255, 255, 255, 0.60);
    background: radial-gradient(79.69% 79.69% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), #FF8114;
}

.blog-generator-button.label {
    padding-left: 7px;
    padding-right: 10px;
    gap: 7px;
    width: auto;
    border-radius: 360px;
    font-size: 12px;
    font-weight: 500;
    color: #A7A7A7;
}

.blog-generator-button.active {
    background: rgba(255, 129, 20, 0.10);
    color: #FF8114;
}

.blog-generator-button.stroke.active svg path {
    stroke: #FF8114;
}

.blog-generator-button.fill.active svg path {
    fill: #FF8114;
}

.blog-generator-divider {
    width: 1px;
    height: 18px;
    background: #333;
    margin: 0 2px;
}

.blog-generator-card-footer {
    padding: 12px 16px;
    background: rgba(21, 21, 21, 0.80);
    backdrop-filter: blur(9px);
    position: absolute;
    bottom: 0;
    width: 100%;
}

.blog-generator-html-container {
    padding: 0 16px;
    max-width: 850px;
    margin: auto;
    flex: 1;
    overflow-y: scroll;
    padding-bottom: 56px;
}

.blog-generator-html-container h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    margin-top: 32px;
    opacity: 0.9;
    font-family: 'Inter', sans-serif;
}

.blog-generator-html-container h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 32px;
    opacity: 0.9;
}

.blog-generator-html-container p {
    color: #A7A7A7;
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
    white-space: pre-wrap;
    font-weight: 400;
}

.blog-post-card {
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background: #151515;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
}

.blog-keywords-select .circle-icon{
    display: none;
}

.blog-keywords-select .select-selection{
    border-radius: 360px !important;
}

.blog-keywords-select .select-multi-value{
    border-radius: 24px !important; 
}

.ql-editor {
    padding: 0 16px 56px;
    width: 100%;
    max-width: 882px;
    margin: auto;
    height: initial !important;
    max-height: 100%;
}

.ql-editor::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.ql-container.ql-snow {
    font-family: 'Open Sans', sans-serif;
    border: 0 !important;
    overflow-y: auto;
}

.ql-editor p {
    color: #A7A7A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-top: 24px;
}

.ql-container.ql-snow .ql-editor ol {
    color: #A7A7A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-top: 24px;
}

.ql-container.ql-snow .ql-editor ul {
    color: #A7A7A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-top: 24px;
}

.ql-snow .ql-editor h1 {
    font-size: 32px;
    line-height: 42px;
    margin-top: 32px;
    color: #ffffffe6;
    font-style: normal;
    font-weight: 600;
}

.ql-snow .ql-editor h2 {
    font-size: 24px;
    line-height: 24px;
    margin-top: 32px;
    color: #ffffffe6;
    font-style: normal;
    font-weight: 600;
}

.ql-snow .ql-editor h3 {
    font-size: 20px;
    line-height: 20px;
    margin-top: 32px;
    color: #ffffffe6;
    font-style: normal;
    font-weight: 600;
}

.ql-snow .ql-editor h4 {
    font-size: 16px;
    line-height: 16px;
    margin-top: 32px;
    color: #ffffffe6;
    font-style: normal;
    font-weight: 600;
}

.ql-editor img {
    display: initial;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 0px 4px 8px 0px #111, 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
    max-height: 425px;
}

.ql-toolbar.ql-snow {
    border: none;
    border-radius: 360px;
    background: #1B1B1B;
    /* display: flex; */
    padding: 6px 8px;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
}

.ql-toolbar.ql-snow::after {
    display: none;
}

.ql-toolbar.ql-snow .ql-formats {
    margin: 0;
}


.ql-snow.ql-toolbar button {
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 6px;
}

.ql-snow.ql-toolbar button:hover {
    background: #151515;
}

.ql-snow.ql-toolbar button.ql-active {
    background: rgba(255, 129, 20, 0.10);
}

.ql-snow.ql-toolbar button.ql-active svg path {
    stroke: #FF8114;
}

.ql-snow .ql-formats {
    display: flex;
    align-items: center;
    gap: 4px;
}

.ql-snow a {
    color: #FF8114;
}

.ql-snow .ql-tooltip {
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    box-shadow: none;
}
.ql-snow .ql-tooltip::before {
    display: none;
}

.ql-snow .ql-picker.ql-header {
    width: 120px;
    color: #A7A7A7;
}

.ql-snow.ql-toolbar .ql-picker-label {
    border-radius: 6px;
    border: none;
    color: #A7A7A7;
}

.ql-snow.ql-toolbar .ql-picker-label:hover {
    color: #A7A7A7;
    background: #151515;
}

.ql-snow.ql-toolbar .select {
    display: flex;
    height: 28px;
    padding: 0px 4px 0px 10px;
    justify-content: space-between;
    align-items: center;
    background: inherit;
    cursor: pointer;
    border-radius: 6px;
    color: #A7A7A7;
}

.ql-snow.ql-toolbar .select:hover {
    background: #151515;
}

.ql-snow.ql-toolbar .select svg {
    margin: 0 6px;
    transition: 0.3s transform ease-in-out;
}

.ql-snow.ql-toolbar .select.ql-active {
    background: rgba(255, 129, 20, 0.10);
    color: #FF8114;
}

.ql-snow.ql-toolbar .select.ql-active svg {
    transform: rotate(180deg);
}

.ql-snow.ql-toolbar .select.ql-active svg path {
    stroke: #FF8114;
}

.ql-font-roboto {
    font-family: 'Roboto', sans-serif;
}
.ql-font-openSans {
    font-family: 'Open Sans', sans-serif;
}
.ql-font-notoSans {
    font-family: 'Noto Sans', sans-serif;
}
.ql-font-montserrat {
    font-family: 'Montserrat', sans-serif;
}
.ql-font-lato {
    font-family: 'Lato', sans-serif;
}

.ql-size-12 {
    font-size: 12px;
}
.ql-size-13 {
    font-size: 13px;
}
.ql-size-14 {
    font-size: 14px;
}
.ql-size-15 {
    font-size: 15px;
}
.ql-size-16 {
    font-size: 16px;
}
.ql-size-20 {
    font-size: 20px;
}
.ql-size-24 {
    font-size: 24px;
}
.ql-size-32 {
    font-size: 32px;
}

.editor-control {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(21, 21, 21, 0.80);
    backdrop-filter: blur(9px);
    position: absolute;
    bottom: 0;
    width: 100%;
}

.generated-blog-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 572px;
    padding: 24px;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid #1F1F1F;
    background-color: rgba(21, 21, 21, 0.80);
}

.generated-blog-popup::before {
    border-radius: 16px;
    display: block;
    backdrop-filter: blur(9px);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.blog-kit-icon-container {
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 0.824px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, #EF6F00 0%, #EF6F00 100%), #FFF;
}

.radial-bg {
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
}

.blog-sugg-swiper {
    overflow: visible;
}

.blog-sugg-swiper::after {
    content: "";
    width: 80px;
    height: 100%;
    display: block;
    top: 0;
    right: -80px;
    position: absolute;
    z-index: 2;
    background: linear-gradient(270deg, #151515 28.49%, rgba(21, 21, 21, 0.00) 90.7%);
}

.blog-sugg-swiper::before {
    content: "";
    width: 80px;
    height: 100%;
    display: block;
    top: 0;
    left: -80px;
    position: absolute;
    z-index: 2;
    background: linear-gradient(90deg, #151515 28.49%, rgba(21, 21, 21, 0.00) 90.7%);
}

.image-uploading::before {
    border-top-color: #FF8114;
    z-index: 0;
}

.primary-button {
    display: flex;
    height: 40px;
    padding: 11px 11px 11px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 360px;
    border: 1px solid transparent;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #331A04;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.primary-button:hover {
    background: radial-gradient(79.69% 79.69% at 50% 100%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
}

.primary-button[disabled] {
    background: #1B1B1B;
    color: white;
}

.primary-button[disabled] > * {
    opacity: 0.3;
}

.primary-input {
    display: flex;
    height: 40px;
    padding: 8px 14px;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ffffffe6;
}

.primary-input::placeholder {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
}

.primary-label {
    display: block;
    color: #A7A7A7;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.icon-plus-card path{
    stroke: #331A04;
}

.primary-button.stroke-plus[disabled] svg path{
    stroke: #FFFFFF;
}

.generated-button.disabled-dark {
    background: #1B1B1B;
    color: rgba(167, 167, 167, 0.2);
    pointer-events: none;
}

.button-goal-back {
    display: flex;
    height: 40px;
    padding: 11px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 360px;
    border: 1px solid #333;
    background: #1B1B1B;
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.dropable-h-full [data-rbd-droppable-context-id="0"] {
    height: 100%;
}
.goal-deadline-cancel {
    border: 1px solid #333;
    background: #1B1B1B;
    overflow: hidden;
    color: #A7A7A7;
    font-size: 14px;
}

.goal-deadline-cancel:hover {
    background: #1B1B1B;
}

.react-datepicker__input-container input::placeholder {
    color: rgba(255, 255, 255, 0.50);
}

.react-datepicker-wrapper~.cursor-pointer {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container input {
    width: 100%;
}
.form-search-radiused__input {
    border-radius: 360px;
    border: 1px solid #333;
    background: #1B1B1B;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 9px;
    padding-left: 38px;
    width: 100%;
}
.modal-outside-close-btn {
    position: absolute;
    top: 0;
    left: calc(100% + 16px);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
}
.modal-outside-close-btn:hover {
    opacity: 0.7;
}
.assets-page-body .big-asset-swiper .card-cust {
    border: 0;
}
.default-select.sort-select-box.select-color-white .select-selection .aaa {
    color: #fff !important;
}
.default-select.sort-select-box.select-color-white .select-selection-arrow svg path,
.select-color-white .react-datepicker-wrapper+.flex-1.cursor-pointer svg path {
    fill-opacity: 1;
}
.select-color-white .react-datepicker__input-container input::placeholder {
    color: #fff;
}
.btn.hover-gradient:hover {
    background: radial-gradient(87.42% 71.88% at 50% 121.88%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%), #1B1B1B;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.custom-multiselect {
    position: relative;
}
.custom-multiselect-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    height: 36px;
    padding: 8px 8px 8px 12px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.custom-multiselect.active .custom-multiselect-header {
    border: 1px solid #FF8114;
}
.custom-multiselect.active .custom-multiselect-header svg {
    transform: rotate(180deg);
}
.custom-multiselect-body-footer {
    border-top: 1px solid #333;
    display: flex;
    align-items: center;
    padding: 10px;
}
.custom-multiselect-body-footer .btn {
    width: 100%;
}
.custom-multiselect-body {
    position: fixed;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #333;
    background: #1B1B1B;
    z-index: 99;
    max-width: 250px;
}
.custom-multiselect-body ul {
    padding: 4px;
    max-height: 258px;
    overflow-y: auto;
}
.custom-multiselect-body ul::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.custom-multiselect-body ul li {
    padding: 11px 6px;
    border-radius: 8px;
    background: #1B1B1B;
    /* overflow: hidden; */
    color: #A7A7A7;
    /* text-overflow: ellipsis; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.custom-multiselect-body ul li+li {
    margin-top: 2px;
}
.option-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #333;
    background: #1B1B1B;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
}
.custom-multiselect-body ul li .option-checkbox {
    margin-right: 8px;
}
.option-checkbox svg {
    display: none;
}
.option-checkbox.active {
    background: #FF8114;
    border-color: #FF8114;
}
.option-checkbox.active svg { 
    display: block;
}
.assets-page-body .sett-checkbox-sub {
    background: #383838;
}
.sett-checkbox-sub:before,
.sett-checkbox-hid:checked+.sett-checkbox-sub:before {
    background: #151515;
    box-shadow: none;
}
.new-word-generator-icon {
    border-radius: 6px;
    border: 0.706px solid rgba(255, 255, 255, 0.5);
    background: radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, #EF6F00 0%, #EF6F00 100%), #FFF;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sm-orange-badge {
    border-radius: 360px;
    background: rgba(255, 129, 20, 0.06);
    color: #FF8114;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 7px;
}
.big-asset-swiper .swiper-arrow-button:hover svg path {
    stroke: #FF8114;
}
.single-word-modal {
    background: rgba(0, 0, 0, 0.7);
}
.single-word-modal .big-asset-swiper .card-cust {
    border: 0;
}
.single-word-modal .modal__content {
    border: 0;
    background: #151515;
}
.single-word-modal .big-asset-swiper .card-cust {
    background: #151515;
}
.single-word-modal .big-asset-swiper .swiper-arrow-button {
    left: -80px;
}
.single-word-modal .big-asset-swiper .swiper-arrow-button.swiper-arrow-button-next {
    left: auto;
    right: -80px;
}
.new-words-swiper .swiper-slide {
    height: initial;
}
.new-words-swiper .card-cust--body.flex-initial {
    flex: initial;
}

.word-selected-button {
    display: flex;
    height: 32px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 360px;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #331A04;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.new-word-datepicker {
    height: 36px;
    z-index: 20;
    max-width: 150px;
}

.new-words-cards-row.row-view.custom-row--5 > div {
    flex: 100%;
    max-width: 100%;
}
.new-words-cards-row.row-view.custom-row--5 .card-cust .mt-16px {
    margin-top: 10px;
}
.new-words-cards-row.row-view.custom-row--5 .card-cust .comment-body {
    padding-top: 10px;
    padding-bottom: 10px;
}
.new-words-cards-row.row-view.custom-row--5 .card-cust .comment-footer {
    margin-top: 10px;
}
.sidebar-nav {
    background: #151515 url(../img/sidebar-infinity-img.svg) no-repeat;
    background-position: 100% 100%;
    border-right: 1px solid #1F1F1F;
}
.left-\[-34px\] {
    left: -34px;
}
.right-\[-34px\] {
    right: -34px;
}
.gen4-content .select-selection {
    min-height: 40px !important;
}
.modal-action-list-item:hover {
    color: #FF8114;
}
.hide-ql-tooltip .ql-tooltip.ql-hidden {
    display: none;
}
.ql-toolbar button.blog-generator-button {
    padding: 3px 5px;
    border-radius: 360px;
}
.ql-toolbar button.blog-generator-button:hover {
    color: #A7A7A7;
}


.noUi-origin {
    /* background-color: var(--primary-10); */
    /* height: 8px !important; */
}

.range-slider.noUi-target .noUi-handle {
    right: 0;
    /* position: relative; */
    width: 18px;
    height: 18px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: none;
    border: none;
    background-color: var(--white);
    border-radius: 300px;
}

.slider-container {
    /* overflow: hidden; */
    padding-bottom: 10px;
}

.range-slider.noUi-target.one-slider--custom-message {
    border-radius: 300px;
}

.blog-keywords-select.press-release-multi .select-selection {
    border-radius: 8px !important;
}

.methods-items-wrpr {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    margin-left: -5px;
}
.method-box-item {
    width: calc(33.33% - 10px);
    margin: 0 5px;
    border-radius: 8px;
    background: #202020;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    border: 1px solid #202020;
}
.method-box-item.active {
    border: 1px solid #FF8114;
    background: #151515;
}
.method-box-item h3 {
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    opacity: 0.9;
}
.method-box-item h3+p {
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    margin-top: 12px;
    display: block;
}
.method-box-item .item-tooltip {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkbox-item-square {
    border-radius: 4px;
    border: 1px solid #383838;
    background: #202020;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkbox-item-square.active {
    background: #FF8114;
}
.button-gradient {
    border-radius: 360px;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #331A04;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.settings-lesson-popup-body .select-selection {
    min-height: 40px !important;
}
.cover-image-item-wrpr {
    border-radius: 8px;
    overflow: hidden;
}
.image-remove-btn {
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kit-form-item {
    width: auto;
    flex: 1;
    margin: 0;
    padding: 14px 10px;
}

.border-1-transparent {
    border: 1px solid transparent;
}
.email-educator-config-component .select-selection.select-selection-focused {
    border: 1px solid #FF8114 !important;
}
body:has(.email-educator-config-component) .select-menu-portal .select2-results__option.select2-results__option--selected,
body:has(.newsletter-settings-popup) .select-menu-portal .select2-results__option.select2-results__option--selected {
    display: flex;
    align-items: center;
}
body:has(.email-educator-config-component) .select-menu-portal .select2-results__option.select2-results__option--selected.option-selected:after,
body:has(.newsletter-settings-popup) .select-menu-portal .select2-results__option.select2-results__option--selected.option-selected:after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    /* select-check-icon.svg */
    background: url("../img/icons/select-check-icon.svg") no-repeat center;
    background-size: cover;
    margin-left: 6px;
}


/* TYPE SELECT INPUT */

.hidden-type-select-box-fields .select-selection {
    /* opacity: 0; */
}

.type-select-input {
    /* position: absolute;
    z-index: 25; */
}

.type-select-dropdown {
    display: none;
    z-index: 25;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;

    border-radius: 8px;
    border: 1px solid #393939;
    background: #232323;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    overflow: hidden;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.type-select-dropdown.open {
    display: block;
}

.type-select-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
}

.type-select-dropdown-item {
    background: transparent;
    color: #A7A7A7;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 16px;
    cursor: pointer;
}

.type-select-dropdown-item.active, .type-select-dropdown-item:hover {
    background: #232323;
    color: #FF8114;
}

.checkboxs-list-parts {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
}
.checkboxs-list-parts+.checkboxs-list-parts {
    margin-top: 10px;
}

.checkboxs-list-parts-text {
    margin-left: 8px;

    color: #A7A7A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.editor-custom-container.one-line {
    display: flex;
    gap: 10px;
}

.dynamic-textarea-clues-block {
    position: absolute;
    width: 100%;
    background: #232323;
    z-index: 25;
    max-height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid #393939;
    background: #232323;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    overflow: hidden;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.dynamic-textarea-clues-block .option{
    background: transparent;
    color: #A7A7A7;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 16px;
    cursor: pointer;
}

.dynamic-textarea-clues-block .option:hover{
    background: #232323;
    color: #FF8114;
}