.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value{
   
    border-radius: 20px;
    background: #EA8207;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    max-width: max-content;
    margin-right: 8px;
    margin-bottom: 8px;

    background-color: rgba(255, 255, 255, 0.16);
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 6px;
}
.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value{
    margin: 0;
}
.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value .select-multi-value:first-child{
    padding: 0;
    font-size: 14px;

}
.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value .select-multi-value:first-child svg{
    display: none;
}
.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value .select-multi-value:last-child svg{
    color: var(--primary);;
}
.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value .select-multi-value:last-child{
    padding: 0;
    padding-left: 10px;
    margin-bottom: 2px;
}
.global-setting-miltiselect .multi-select .select-selection .bbb>.select-multi-value+.select-multi-value{
    margin-left: 10px;
}
.global-setting-miltiselect .multi-select .indicators-container{
    display: block;
    
}
.global-setting-miltiselect .multi-select .indicators-container div:not(.select-selection-arrow){
    display: none;
}